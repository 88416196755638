import { createColumnHelper } from "@tanstack/react-table";
import React from "react";
import { toggleDrawer } from "../../features/actionSlice";
import { setOneWallet } from "../../features/walletSlice";
import { useDispatch, useSelector } from "react-redux";
import Table from "../table/Table";
import DateTime from "../excerpts/DateTime";
import Amount from "../excerpts/Amount";
 

const AllWallets = () => {
  const columnHelper = createColumnHelper();
  const columns = [
    columnHelper.accessor("name", {
      cell: (info) => info.getValue(),
      header: () => <span>Name</span>,
    }),
    columnHelper.accessor("currency", {
      cell: (info) => info.getValue(),
      header: () => <span>Currency</span>,
    }),
    columnHelper.accessor("ledger_balances", {
      cell: (info) => <Amount amount={info.getValue()} />,
      header: () => <span>Ledger Balance</span>,
    }),
    columnHelper.accessor("available_balances", {
      cell: (info) => <Amount amount={info.getValue()} />,
      header: () => <span>Avaliable Balance</span>,
    }),
    
    columnHelper.accessor("created_at", {
      cell: (info) => <DateTime date={info.getValue()} />,
      header: () => <span>Date</span>,
    }),
  ]; 

  const { wallets } = useSelector((state) => state.wallet);
  const dispatch = useDispatch();
  const onClick = (data) => {
    dispatch(setOneWallet(data));
    dispatch(toggleDrawer("wallet_detail"));
  };
  return (
    <div>
      <Table
        data={wallets}
        columns={columns}
        click={onClick}
        filter={"true"}
        filterParams={true}
        name={"All Wallets"}
      />
    </div>
  );
};

export default AllWallets;
