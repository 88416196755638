import { createColumnHelper } from '@tanstack/react-table';
import Table from '../../conponents/table/Table';
import DateTime from '../../conponents/excerpts/DateTime';
import { useDispatch, useSelector } from 'react-redux';
import { setEditMode, toggleDrawer } from '../../features/actionSlice';
import {  ButtonLight } from '../../styles/DefaultStyles';
import { oneBlogPost, setBlogPost } from '../../features/blogSlice';

const BlogPost = () => {
    const Body = (value) => {
        return <div dangerouslySetInnerHTML={{ __html: value }} />;
      };
  const columnHelper = createColumnHelper();
  const columns = [
    columnHelper.accessor("title", {
      cell: (info) => info.getValue(),
      header: () => <span>Title</span>,
    }),
    columnHelper.accessor( 'summary', {
      cell: (info) => info.getValue(),
      header: () => <span>Summary</span>,
    }),
    columnHelper.accessor("body_html", {
      cell: (info) =>Body(info.getValue()) ,
      header: () => <span>Body</span>,
    }),
    columnHelper.accessor( 'reading_time', {
        cell: (info) => info.getValue(),
        header: () => <span>Reading Time</span>,
      }),
    columnHelper.accessor("created_at", {
      cell: (info) =><DateTime val={info} date={info.getValue()} />,
      header: () => <span>Date</span>,
    }),
    columnHelper.accessor("action", {
        cell: (info) => (
          <ButtonLight small>
            <button
              onClick={(e) => {
                e.stopPropagation();
                editAnnouncement(info.row.original.id);
              }}
            >
              Edit Details
            </button>
          </ButtonLight>
        ),
        header: () => <span>Action</span>,
      }),
  ];
  const {blogs} = useSelector((state)=> state.blog)
  const dispatch = useDispatch()
  const onClick = (data) => {
    dispatch(toggleDrawer('blog_post_details'))
    dispatch(oneBlogPost(data))
  };

  const editAnnouncement= (id)=>{
    dispatch(toggleDrawer('add_edit_blog_post'))
    dispatch(setEditMode('edit'))
    dispatch(setBlogPost(id))
  }
  return (
    <div>
      <h1>All Blog Post</h1>
     <div style={{ display: "flex", justifyContent: "flex-end" }}>
      
        
      </div>
      <Table
          data={blogs}
          columns={columns}
          click={onClick}
          filter={"true"}
          filterParams={true}
          name={'All Blog Posts'}
      />
    </div>
  )
}

export default BlogPost
