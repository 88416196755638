import { createColumnHelper } from '@tanstack/react-table';
import Table from '../table/Table';
import DateTime from '../excerpts/DateTime';
import { useDispatch, useSelector } from 'react-redux';
import { setEditMode, toggleDrawer } from '../../features/actionSlice';
import {  ButtonLight } from '../../styles/DefaultStyles';
import { getAllAnnouncement, setAnnouncement } from '../../features/announcementSlice';
import { useEffect } from 'react';

const Announcement = () => {
 
  const columnHelper = createColumnHelper();
  const columns = [
    columnHelper.accessor("title", {
      cell: (info) => info.getValue(),
      header: () => <span>Title</span>,
    }),
    columnHelper.accessor( 'description', {
      cell: (info) => info.getValue(),
      header: () => <span>Description</span>,
    }),
    columnHelper.accessor("blog_slug", {
      cell: (info) => info.getValue(),
      header: () => <span>Email</span>,
    }),
  
    columnHelper.accessor("created_at", {
      cell: (info) =><DateTime val={info} date={info.getValue()} />,
      header: () => <span>Date</span>,
    }),
    columnHelper.accessor("action", {
        cell: (info) => (
          <ButtonLight small>
            <button
              onClick={(e) => {
                e.stopPropagation();
                editAnnouncement(info.row.original.id);
              }}
            >
              Edit Details
            </button>
          </ButtonLight>
        ),
        header: () => <span>Action</span>,
      }),
  ];
  const {announcements} = useSelector((state)=> state.announcement)
  const dispatch = useDispatch()
  const onClick = (data) => {
    dispatch(toggleDrawer('user_detail'))
  };
  const onEnable =()=>{
    dispatch(toggleDrawer('add_edit_announcement'))
    dispatch(setEditMode('add'))
  }

  const editAnnouncement= (id)=>{
    dispatch(toggleDrawer('add_edit_announcement'))
    dispatch(setEditMode('edit'))
    dispatch(setAnnouncement(id))
  }
  
  useEffect(()=>{
    dispatch(getAllAnnouncement());
  },[dispatch])
  return (
    <div>
      <h1>All Announcement</h1>
     <div style={{ display: "flex", justifyContent: "flex-end" }}>
      <ButtonLight onClick={onEnable}>
          <button style={{marginRight:"1rem"}}>Create Announcement</button>
        </ButtonLight>
        
      </div>
      <Table
          data={announcements}
          columns={columns}
          click={onClick}
          filter={"true"}
          filterParams={true}
          name={'All Users'}
      />
   
    </div>
  )
}

export default Announcement
