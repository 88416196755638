import { createColumnHelper } from "@tanstack/react-table";
import Table from "../../conponents/table/Table";
import DateTime from "../../conponents/excerpts/DateTime";
import { useDispatch, useSelector } from "react-redux";
import { setEditMode, toggleDrawer } from "../../features/actionSlice";
import { ButtonLight } from "../../styles/DefaultStyles";
import { setBlogCategory } from "../../features/blogSlice";

const BlogCategory = () => {
  const columnHelper = createColumnHelper();
  const columns = [
    columnHelper.accessor("name", {
      cell: (info) => info.getValue(),
      header: () => <span>Name</span>,
    }),
    columnHelper.accessor("category_slug", {
      cell: (info) => info.getValue(),
      header: () => <span>Category Slug</span>,
    }),

    columnHelper.accessor("created_at", {
      cell: (info) => <DateTime val={info} date={info.getValue()} />,
      header: () => <span>Date</span>,
    }),
    columnHelper.accessor("action", {
      cell: (info) => (
        <ButtonLight small>
          <button
            onClick={(e) => {
              e.stopPropagation();
              editAnnouncement(info.row.original.id);
            }}
          >
            Edit Details
          </button>
        </ButtonLight>
      ),
      header: () => <span>Action</span>,
    }),
  ];
  const { cats } = useSelector((state) => state.blog);
  const dispatch = useDispatch();

  const onEnable = () => {
    dispatch(toggleDrawer("add_edit_blog_category"));
    dispatch(setEditMode("add"));
  };

  const editAnnouncement = (id) => {
    dispatch(toggleDrawer("add_edit_blog_category"));
    dispatch(setEditMode("edit"));
    dispatch(setBlogCategory(id));
  };
  return (
    <div>
      <h1>All Categories</h1>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <ButtonLight onClick={onEnable}>
          <button style={{ marginRight: "1rem" }}>Create Blog Category</button>
        </ButtonLight>
      </div>
      <Table
        data={cats}
        columns={columns}
        filter={"true"}
        filterParams={true}
        name={"All categories"}
      />
    </div>
  );
};

export default BlogCategory;
