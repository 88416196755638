import React, { useState } from "react";
import { Button, InputWrap } from "../../styles/DefaultStyles";
import DrawerHeader from "../drawer/DrawerHeader";
import { BtnWrapper, InnerWrapper, Wrapper } from "./form.styled";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch, useSelector } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import Switch from "../excerpts/Switch";
import CustomSelect from "../excerpts/Select";
import { modUser } from "../../utils/mods";
import { notifyError, notifySuccess } from "../../utils/toast";
import { enableDisableUser, getAllUsers } from "../../features/userSlice";
import CustomInput from "../excerpts/Input";
import { renderSourceDisable } from "./EnableDisableBillItem";

const render = (value) => {
  if (value === true) {
    return "Yes";
  } else {
    return "No";
  }
};
const schema = yup.object({
  id: yup.string().required(),
});

const EnableDisableUser = ({ close }) => {
  const [loading, setLoading] = useState(false);
  const { editMode } = useSelector((state) => state.action);
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);
  const isEdit = () => editMode === "edit";
  const method = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      id: isEdit() ? user.id : "",
      firstName: isEdit() ? user.first_name : "",
      lastName: isEdit() ? user.last_name : "",
      enable: isEdit() ? user.locked_status : "",
    },
  });
  const { handleSubmit, reset } = method;
  const [activateCategory, setActivateCategory] = useState(
    user.locked_status ? true : false
  );

  const onSubmit = (data) => {
    setLoading(true);
    let payload = {
      user_id: data.id,
      locked: activateCategory,
    };
    onToggleBillCat(payload);
  };

  const onToggleBillCat = (data) => {
    setLoading(true);

    dispatch(enableDisableUser(data))
      .unwrap()
      .then(() => {
        setLoading(false);
        notifySuccess("updated");
        dispatch(getAllUsers());
        reset();
        close();
      })
      .catch((err) => {
        setLoading(false);
        notifyError(
          err?.data?.response_message || err?.response_message || "Failed"
        );
      });
  };

  return (
    <Wrapper>
      <InnerWrapper>
        <DrawerHeader title="Enable/Disable" close={close} />
        <CustomInput method={method} disabled label="ID *" name="id" />
        <CustomInput
          method={method}
          label="First Name *"
          disabled
          name="firstName"
        />
        <CustomInput
          method={method}
          label="Last Name *"
          disabled
          name="lastName"
        />
        <h4 style={{ display: "flex", marginBottom: "1rem", gap: "2rem" }}>
          <span>User Locked ?</span>
          <span>{render(user.locked_status)}</span>
        </h4>
        <InputWrap>
          <label className="inline-label">
            <span>locked?</span>
            <Switch
              checked={activateCategory}
              onChecked={() => setActivateCategory(!activateCategory)}
              name="enable/disable-bill-item"
            />
          </label>
        </InputWrap>
      </InnerWrapper>
      <BtnWrapper>
        <Button disabled={loading}>
          <button onClick={handleSubmit(onSubmit)}>
            {loading ? "Loading..." : "Submit"}
          </button>
        </Button>
      </BtnWrapper>
    </Wrapper>
  );
};

export default EnableDisableUser;
