import React, { useState } from 'react';
import { formatNumberWithDecimal } from '../../utils/numbers';
import ErrorMsg from '../alert/ErrorMsg';
import { InputWrap } from '../../styles/DefaultStyles';


const AmountInput = ({ name = 'amount', label = 'Amount', method }) => {
  const [hasPeriod, setHasPeriod] = useState(false);

  const {
    setValue,
    register,
    formState: { errors },
  } = method;

  return (
    <>
      <InputWrap>
        <label>{label}</label>
        <input
          {...register(`${name}`)}
          onChange={(e) => {
            if (e.target.value.indexOf('.') === -1) {
              setHasPeriod(false);
            }
            return setValue(`${name}`, formatNumberWithDecimal(e.target.value));
          }}
          onKeyPress={(e) => {
            if (e.key === '.') {
              if (hasPeriod) {
                e.preventDefault();
              } else {
                setHasPeriod(true);
              }
            }
            if (!/[\d.,]/.test(e.key)) {
              e.preventDefault();
            }
          }}
        />
      </InputWrap>
      {errors.number && <ErrorMsg msg={errors.number.message} />}
    </>
  );
};

export default AmountInput;
