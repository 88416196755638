import React from "react";
import DrawerHeader from "../../drawer/DrawerHeader";
import { InnerWrapper, TrDetail, Wrapper } from "../form.styled";
import Amount from "../../excerpts/Amount";
import OnlyDate from "../../excerpts/OnlyDate";

import { useSelector } from "react-redux";

const BillsTransactionDetails = ({ close, data }) => {
  const { details } = useSelector((state) => state.transaction.billTransactions);
  return (
    <Wrapper>
      <InnerWrapper>
        <DrawerHeader title="Transaction Details" tag="view Transaction" close={close} />
        <div>
          <TrDetail>
            <span className="tr-title">Transaction Id</span>
            <span className="tr-data">{details.transactionId}</span>
          </TrDetail>
          <TrDetail>
            <span className="tr-title">Email</span>
            <span className="tr-data">{details.email}</span>
          </TrDetail>
          <TrDetail>
            <span className="tr-title">Product Name</span>
            <span className="tr-data">{details.product_name}</span>
          </TrDetail>
          <TrDetail>
            <span className="tr-title">Response</span>
            <span className="tr-data">{details.response_description}</span>
          </TrDetail>
          <TrDetail>
            <span className="tr-title">Commission</span>
            <span className="tr-data">{details.commission}</span>
          </TrDetail>
          <TrDetail>
            <span className="tr-title">Amount</span>
            <span className="tr-data"><Amount amount={details.amount}/></span>
          </TrDetail>
          <TrDetail>
            <span className="tr-title">Status</span>
            <span className="tr-data">{details.status}</span>
          </TrDetail>
          <TrDetail>
            <span className="tr-title">Date</span>
            <span className="tr-data">
              <OnlyDate date={details.created_at} />
            </span>
          </TrDetail>
          <TrDetail>
            <span className="tr-title">Phone</span>
            <span className="tr-data">{details.phone}</span>
          </TrDetail>
         
        </div>
      </InnerWrapper>
    </Wrapper>
  );
};

export default BillsTransactionDetails;
