import React from "react";
import DrawerHeader from "../../drawer/DrawerHeader";
import { InnerWrapper, TrDetail, Wrapper } from "../form.styled";
import Amount from "../../excerpts/Amount";
import OnlyDate from "../../excerpts/OnlyDate";
import { useSelector } from "react-redux";

const WalletSettlementDetails = ({ close}) => {
  const { details } = useSelector((state) => state.wallet.walletSettlement);
  return (
    <Wrapper>
      <InnerWrapper>
        <DrawerHeader title="Wallet Details" tag="view Wallet" close={close} />
        <div>
          {/* <TrDetail>
            <span className="tr-title">Transaction Ref</span>
            <span className="tr-data">{details.transactionReference}</span>
          </TrDetail> */}
          <TrDetail>
            <span className="tr-title">Account Name</span>
            <span className="tr-data">{details.accountName}</span>
          </TrDetail>
          <TrDetail>
            <span className="tr-title">Account Number</span>
            <span className="tr-data">{details.accountNumber}</span>
          </TrDetail>
          <TrDetail>
            <span className="tr-title">Amount</span>
            <span className="tr-data"><Amount amount={details.amount}/></span>
          </TrDetail>
          <TrDetail>
            <span className="tr-title">Clearing Fee</span>
            <span className="tr-data"><Amount amount={details.clearingFeeAmount}/></span>
          </TrDetail>
          <TrDetail>
            <span className="tr-title">Transfer Fee</span>
            <span className="tr-data"><Amount amount={details.transferFeeAmount}/></span>
          </TrDetail>
          <TrDetail>
            <span className="tr-title">Payment Method</span>
            <span className="tr-data">{details.paymentMethod}</span>
          </TrDetail>
          <TrDetail>
            <span className="tr-title">Payer Name</span>
            <span className="tr-data">{details.payerName}</span>
          </TrDetail>
          <TrDetail>
            <span className="tr-title">Date</span>
            <span className="tr-data">
              <OnlyDate date={details.created_at} />
            </span>
          </TrDetail>
          <TrDetail>
            <span className="tr-title">Status</span>
            <span className="tr-data">{details.statusMessage}</span>
          </TrDetail>
        </div>
      </InnerWrapper>
    </Wrapper>
  );
};

export default WalletSettlementDetails;
