import React from 'react';
import styled from 'styled-components';

const Status = ({ status, noRadius, big, fill }) => {
  function genStatus() {
    let s = status.toLowerCase();
    if (s === 'success' || s === 'paid' || s === 'active') return 'success';
    if (s === 'failed' || s === 'not paid' || s === 'disabled') return 'failed';
    return 'processing';
  }

  return (
    <StatusWrap fill={fill} big={big} noRadius={noRadius} status={genStatus()}>
      {status}
    </StatusWrap>
  );
};

const StatusWrap = styled.div`
  width: ${(props) => (props.fill ? '100%' : '100px')};
  height: ${(props) => (props.fill ? '50px' : 'auto')};
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${(props) => (props.big ? '10px' : '6px 10px')};
  border-radius: ${(props) => (props.noRadius ? '0px' : '15px')};
  font-size: ${(props) => (props.big ? '16px' : '14px')};
  background-color: ${(props) => props.theme[props.status]};
  color: ${(props) => props.theme[props.status + '_dark']};
`;

export default Status;
