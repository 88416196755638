import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api } from "../service/axiosinstance";
import { BASE_URL } from "../utils/constants";

const initialState = {
  details: null,
  status: "loading",
  transactions: [],
  billTransactions: {
    status: "loading",
    data: [],
    details: null,
  },
  latestTransactions: {
    status: "loading",
    data: [],
    details: null,
  },
  billSubscriptions: {
    status: "loading",
    data: [],
    details: null,
  },
};

export const getAllTransactions = createAsyncThunk(
  "transactions/getAllTransactions",
  async (_, thunkAPI) => {
    try {
      const { data } = await api.get(`${BASE_URL}/admin/get_all_transactions`);
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response);
    }
  }
);

export const getBillTransactions = createAsyncThunk(
  "transactions/getBillTransactions",
  async (_, thunkAPI) => {
    try {
      const { data } = await api.get(
        `${BASE_URL}/admin/get_bills_transactions`
      );
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response);
    }
  }
);

export const getLatestTransactions = createAsyncThunk(
  "transactions/getlatestTransactions",
  async (_, thunkAPI) => {
    try {
      const { data } = await api.get(`${BASE_URL}/admin/latest_transactions`);
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response);
    }
  }
);

export const getBillSubscriptions = createAsyncThunk(
  "transactions/getBillSubscriptions",
  async (_, thunkAPI) => {
    try {
      const { data } = await api.get(`${BASE_URL}/admin/bills_subscriptions`);
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response);
    }
  }
);

const transactionSlice = createSlice({
  name: "transaction",
  initialState,
  reducers: {
    setAllTransactionDetails(state, action) {
      state.details = action.payload;
    },
    setBillTransactionDetails(state, action) {
      state.billTransactions.details = action.payload;
    },
    setLatestTransactionDetails(state, action) {
      state.latestTransactions.details = action.payload;
    },
    setBillSubTransactionDetails(state, action) {
      state.billSubscriptions.details = action.payload;
    },
  },
  extraReducers: {
    // Get all Transactions
    [getAllTransactions.pending]: (state) => {
      state.status = "loading";
    },
    [getAllTransactions.fulfilled]: (state, action) => {
      state.status = "idle";
      state.transactions = action.payload.data;
    },
    [getAllTransactions.rejected]: (state) => {
      state.status = "idle";
    },
    //Bills transactions
    [getBillTransactions.pending]: (state) => {
      state.billTransactions.status = "loading";
    },
    [getBillTransactions.fulfilled]: (state, action) => {
      state.billTransactions.status = "idle";
      state.billTransactions.data = action.payload.data;
    },
    [getBillTransactions.rejected]: (state) => {
      state.billTransactions.status = "idle";
    },
    //latest transaction
    [getLatestTransactions.pending]: (state) => {
      state.latestTransactions.status = "loading";
    },
    [getLatestTransactions.fulfilled]: (state, action) => {
      state.latestTransactions.status = "idle";
      state.latestTransactions.data = action.payload.data;
    },
    [getLatestTransactions.rejected]: (state) => {
      state.latestTransactions.status = "idle";
    },
    //bills Subscription
    [getBillSubscriptions.pending]: (state) => {
      state.billSubscriptions.status = "loading";
    },
    [getBillSubscriptions.fulfilled]: (state, action) => {
      state.billSubscriptions.status = "idle";
      state.billSubscriptions.data = action.payload.data;
    },
    [getBillSubscriptions.rejected]: (state) => {
      state.billSubscriptions.status = "idle";
    },
  },
});
export const {
  setAllTransactionDetails,
  setLatestTransactionDetails,
  setBillSubTransactionDetails,
  setBillTransactionDetails,
} = transactionSlice.actions;
export default transactionSlice.reducer;
