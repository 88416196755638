import React from "react";
import { Main, Overlay, Wrapper } from "./Drawer.styled";

const Drawer = ({ close, children, visible }) => {
  return (
    <Wrapper visible={visible}>
      <Overlay onDoubleClick={close} title="Double click to close" />
      <Main visible={visible}>{children}</Main>
    </Wrapper>
  );
};

export default Drawer;
