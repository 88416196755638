import React, { useEffect, useState } from "react";
import Tab from "../conponents/tabs/Tab";
import BillItems from "../conponents/billSettings/BillItems";
import BillService from "../conponents/billSettings/BillService";
import BillCategories from "../conponents/billSettings/BillCategories";
import SwitchCode from "../conponents/billSettings/SwitchCode";
import { useDispatch } from "react-redux";
import { getBillCategory, getBillItems, getBillServices, getSwitchCode } from "../features/billSlice";
import { getProviders } from "../features/providerSlice";

const BillSettings = () => {
  const dispatch = useDispatch();
  useEffect(()=>{
    
    dispatch(getSwitchCode());
    dispatch(getBillCategory())
    dispatch(getBillServices());
    dispatch(getBillItems());
    dispatch(getProviders());
  },[dispatch])
  const tabList = [
    { name: "Bill Categories", alias: "bill-categories" },
    { name: "Bill Service", alias: "bill-service" },
    { name: "Bill Items", alias: "bill-items" },
    { name: "Switch Code", alias: "switch-code" },
  ];

  const [activeTab, setActiveTab] = useState("bill-categories");
  return (
    <div>
      <div style={{ margin: "2rem 0" }}>
        <h1>Bill Settings</h1>
      </div>
      <Tab tabs={tabList} activeTab={activeTab} setActiveTab={setActiveTab} />
      <div>
        {activeTab === tabList[0].alias && <BillCategories />}
        {activeTab === tabList[1].alias && <BillService />}
        {activeTab === tabList[2].alias && <BillItems />}
        {activeTab === tabList[3].alias && <SwitchCode />}
      </div>
    </div>
  );
};

export default BillSettings;
