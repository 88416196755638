import { createColumnHelper } from "@tanstack/react-table";
import Table from "../../conponents/table/Table";
import DateTime from "../../conponents/excerpts/DateTime";
import { useDispatch, useSelector } from "react-redux";
import { toggleDrawer } from "../../features/actionSlice";
import { setOneEmail } from "../../features/emailSlice";
import ReduceText from "../excerpts/ReduceText";

const AllEmails = () => {
 
  const columnHelper = createColumnHelper();
  const columns = [
    columnHelper.accessor("subject", {
      cell: (info) => info.getValue(),
      header: () => <span>Subject</span>,
    }),
    columnHelper.accessor("message", {
      cell: (info) =>   <ReduceText text={info.getValue()}/>,
      header: () => <span>message</span>,
    }),
    columnHelper.accessor("user_category", {
      cell: (info) => info.getValue(),
      header: () => <span>Category</span>,
    }),
    columnHelper.accessor("created_at", {
      cell: (info) => <DateTime val={info} date={info.getValue()} />,
      header: () => <span>Date</span>,
    }),
  ];
  const { emails } = useSelector((state) => state.email);
  const dispatch = useDispatch();
  const onClick = (data) => {
    dispatch(setOneEmail(data))
    dispatch(toggleDrawer("email"));
  };

  return (
    <div>
      <h1>All Emails</h1>
      <Table
        data={emails}
        columns={columns}
        click={onClick}
        filter={"true"}
        filterParams={true}
        name={"All Emails"}
      />
    </div>
  );
};

export default AllEmails;
