import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BASE_URL } from "../utils/constants";
import { api } from "../service/axiosinstance";

const initialState = {
  status: "loading",
  wallets: [],
  wallet: null,
  walletTransactions: {
    status: "loading",
    data: [],
    details: null,
  },
  walletSettlement: {
    status: "loading",
    data: [],
    details: null,
  },
};

export const getAllWallets = createAsyncThunk(
  "wallet/getAllWallets",
  async (_, thunkAPI) => {
    try {
      const { data } = await api.get(`${BASE_URL}/admin/wallets`);
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response);
    }
  }
);

export const getWalletTransactions = createAsyncThunk(
  "wallet/getWalletTransactions",
  async (_, thunkAPI) => {
    try {
      const { data } = await api.get(`${BASE_URL}/admin/wallet_transactions`);
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.repsonse);
    }
  }
);
export const getWalletSettlement = createAsyncThunk(
  "wallet/getWalletSettlement",
  async (_, thunkAPI) => {
    try {
      const { data } = await api.get(`${BASE_URL}/admin/wallet_settlement`);
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.repsonse);
    }
  }
);
const walletSlice = createSlice({
  name: "wallet",
  initialState,
  reducers: {
    setOneWallet(state, action) {
      state.wallet = action.payload;
    },
    setWalletTransaction(state, action) {
      state.walletTransactions.details = action.payload;
    },
    setWalletSettlement(state, action) {
      state.walletSettlement.details = action.payload;
    },
  },
  extraReducers: {
    // getAllWallets
    [getAllWallets.pending]: (state) => {
      state.status = "loading";
    },
    [getAllWallets.fulfilled]: (state, action) => {
      state.status = "idle";
      state.wallets = action.payload.data;
    },
    [getAllWallets.rejected]: (state) => {
      state.status = "idle";
    },
    //wallet transactions
    [getWalletTransactions.pending]: (state) => {
      state.walletTransactions.status = "loading";
    },
    [getWalletTransactions.fulfilled]: (state, action) => {
      state.walletTransactions.status = "idle";
      state.walletTransactions.data = action.payload.data;
    },
    [getWalletTransactions.rejected]: (state) => {
      state.walletTransactions.status = "idle";
    },
    // wallet Settlements
    [getWalletSettlement.pending]: (state) => {
      state.walletSettlement.status = "loading";
    },
    [getWalletSettlement.fulfilled]: (state, action) => {
      state.walletSettlement.status = "idle";
      state.walletSettlement.data = action.payload.data;
    },
    [getWalletSettlement.rejected]: (state) => {
      state.walletSettlement.status = "idle";
    },
  },
});
export const { setOneWallet, setWalletTransaction, setWalletSettlement } =
  walletSlice.actions;
export default walletSlice.reducer;
