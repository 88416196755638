export const lightTheme = {
  name: 'Light',
  accent: '#0055BB',
  accent2: 'rgba(0, 85, 187, 0.88)',
  sidenav_bg: '#062C6E',
  color1: '#0B0E26',
  color2: 'rgba(0,0,0,0.5)',
  color3: 'rgba(191, 189, 189, 0.67)',
  color4: '#ffffff',
  color5: '#26134F',
  color6: '#030749',
  color7: 'rgba(3, 7, 73, 0.4)',
  color8: '#1A0B43',
  color9: '#C4C4C4',
  color10: '#F4744B',
  radius1: '8px',
  hover_bg: 'rgba(229, 229, 229, 0.16)',
  line_bg: 'rgba(207, 207, 207, 1)',
  transition1: 'all 200ms ease-in-out',
  card_bg: 'rgba(248, 247, 248, 1)',
  card_bg_dark: '#eee',
  accent_light: 'rgba(0, 85, 187, 0.11)',
  accent_light2: 'rgba(0, 85, 187, 0.22)',
  accent_dark: '#062C6E',
  border_color: '#DBDBDB',
  add_member_text: '#016FF2',
  settings_head: '#9A9CB6',
  green: '#05D188',
  processing: 'rgba(0, 85, 187, 0.11)',
  processing_dark: '#0055BB',
  success: 'rgba(56, 201, 118, 0.1)',
  success_dark: 'rgba(5, 209, 136, 1)',
  failed: 'rgba(254, 80, 80, 0.09)',
  failed_dark: 'rgba(254, 80, 80, 1)',
  danger: '#FE5050',
  danger_dark: 'darkred',
  danger_light: 'rgba(255, 238, 239, 1)',
  danger_light2: 'rgba(255, 0, 0, .1)',
  input_bg: '#F8F7F8',
  progress_complete: '#17C894',
};

/**
 *   name: 'Light',
  accent: '#0055BB',
  accent2: 'rgba(0, 85, 187, 0.88)',
  sidenav_bg: '#062C6E',
  color1: '#0B0E26',
  color2: 'rgba(0,0,0,0.5)',
  color3: 'rgba(191, 189, 189, 0.67)',
  color4: '#ffffff',
  color5: '#26134F',
  color6: '#030749',
  color7: 'rgba(3, 7, 73, 0.4)',
  color8: '#1A0B43',
  radius1: '8px',
  hover_bg: 'rgba(229, 229, 229, 0.16)',
  line_bg: 'rgba(207, 207, 207, 1)',
  transition1: 'all 200ms ease-in-out',
  card_bg: 'rgba(248, 247, 248, 1)',
  card_bg_dark: '#eee',
  accent_light: 'rgba(0, 85, 187, 0.11)',
  accent_light2: 'rgba(0, 85, 187, 0.22)',
  accent_dark: '#062C6E',
  border_color: '#DBDBDB',
  add_member_text: '#016FF2',
  settings_head: '#9A9CB6',
  green: '#05D188',
  processing: 'rgba(0, 85, 187, 0.11)',
  processing_dark: '#0055BB',
  success: 'rgba(56, 201, 118, 0.1)',
  success_dark: 'rgba(5, 209, 136, 1)',
  failed: 'rgba(254, 80, 80, 0.09)',
  failed_dark: 'rgba(254, 80, 80, 1)',
  danger: '#FE5050',
  input_bg: '#F8F7F8',
 */
