import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api } from "../service/axiosinstance";
import { BASE_URL } from "../utils/constants";

const initialState = {
  status: "loading",
  blog: {},
  blogs: [],
  cat: {},
  cats: [],
};
///Blog Posts
export const getBlogPosts = createAsyncThunk(
  "getBlogPosts/blog",
  async (_, thunkAPI) => {
    try {
      const { data } = await api.get(`${BASE_URL}/admin/all_blog_post`);
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response);
    }
  }
);
export const createBlogPosts = createAsyncThunk(
  "createBlogPosts/blog",
  async (payload, thunkAPI) => {
    try {
      const { data } = await api.post(
        `${BASE_URL}/admin/create_blog_post`,
        payload
      );
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response);
    }
  }
);
export const updateBlogPosts = createAsyncThunk(
  "updateBlogPosts/blog",
  async (payload, thunkAPI) => {
    try {
      const { data } = await api.put(
        `${BASE_URL}/admin/update_blog_post/${payload.id}`,
        payload.data
      );
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response);
    }
  }
);
export const getOneBlogPosts = createAsyncThunk(
  "getOneBlogPosts/blog",
  async (payload, thunkAPI) => {
    try {
      const { data } = await api.get(`${BASE_URL}/one_blog_post/${payload}`);
      
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response);
    }
  }
);

// Blog Category

export const createBlogCategory = createAsyncThunk(
  "createBlogCategory/blog",
  async (payload, thunkAPI) => {
    try {
      const { data } = await api.post(
        `${BASE_URL}/admin/create_blog_category`,
        payload
      );
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response);
    }
  }
);
export const updateBlogCategory = createAsyncThunk(
  "updateBlogCategory/blog",
  async (payload, thunkAPI) => {
    try {
      const { data } = await api.put(
        `${BASE_URL}/update_blog_category/${payload.id}`,
        payload.data
      );
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response);
    }
  }
);
export const getBlogCategory = createAsyncThunk(
  "getBlogCategory/blog",
  async (_, thunkAPI) => {
    try {
      const { data } = await api.get(`${BASE_URL}/all_blog_category`);
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response);
    }
  }
);

const blogSlice = createSlice({
  name: "blog",
  initialState,
  reducers: {
    oneBlogPost(state,action){
        state.blog = action.payload
    },
    setBlogPost(state,action){
        const id = action.payload;
        state.blog = state.blogs.find(
            (data) => data.id === id
          );
    },
    setBlogCategory(state,action){
        const id = action.payload;
        state.cat = state.cats.find(
            (data) => data.id === id
          );
    },
    
  },
  extraReducers: {
    //blog Category
    [getBlogCategory.pending]:(state)=>{
        state.status ='loading';
    },
    [getBlogCategory.fulfilled]:(state,action)=>{
        state.status ='idle';
        state.cats =action.payload.data;
    },
     [getBlogCategory.rejected]:(state)=>{
        state.status ='loading';
    },

    [updateBlogCategory.pending]:(state)=>{
        state.status ='loading';
    },
    [updateBlogCategory.fulfilled]:(state)=>{
        state.status ='idle';
    },
    [updateBlogCategory.rejected]:(state)=>{
        state.status ='idle';
    },

    // Blog Posts
    //get all blog posts
    [getBlogPosts.pending]:(state)=>{
        state.status ='loading';
    },
    [getBlogPosts.fulfilled]:(state,action)=>{
        state.status ='idle';
        state.blogs = action.payload.data
    },
    [getBlogPosts.pending]:(state)=>{
        state.status ='idle';
    },

    //update 
    [updateBlogPosts.pending]:(state)=>{
        state.status ='loading';
    },
    [updateBlogPosts.fulfilled]:(state)=>{
        state.status ='idle';
    },
    [updateBlogPosts.rejected]:(state)=>{
        state.status ='idle';
    },
    //get one blog post
    [getOneBlogPosts.pending]:(state)=>{
        state.status ='loading';
    },
    [getOneBlogPosts.fulfilled]:(state,action)=>{
        state.status ='idle';
        state.blog = action.payload.data[0];
    },
    [getOneBlogPosts.rejected]:(state)=>{
        state.status ='idle';
    },
  },
});
export const{setBlogPost,setBlogCategory,oneBlogPost} = blogSlice.actions
export default blogSlice.reducer;
