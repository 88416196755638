import React, { useEffect, useState } from "react";
import { Button } from "../../styles/DefaultStyles";
import DrawerHeader from "../drawer/DrawerHeader";
import { BtnWrapper, InnerWrapper, Wrapper } from "./form.styled";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch, useSelector } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import { unFormatNumber } from "../../utils/numbers";
import CustomInput from "../excerpts/Input";
import AmountInput from "../excerpts/AmountInput";
import {
  createBillItem,
  getBillItems,
  getCategoryService,
  updateBillItem,
} from "../../features/billSlice";
import CustomSelect from "../excerpts/Select";
import { modBillService, modService } from "../../utils/mods";
import { notifyError, notifySuccess } from "../../utils/toast";

const schema = yup.object({
  name: yup.string().required(),
  variationCode: yup.string().required(),
  amount: yup.string().required(),
  serviceId: yup.string().required(),
  billersServices: yup.string().required(),
});

const titles = { add: "Add New bill Item", edit: "Edit bill Item" };
const btnTexts = { add: "Create Bill Item", edit: "Update bill item" };

const AddEditBillItem = ({ close }) => {
  const { editMode } = useSelector((state) => state.action);
  const { data } = useSelector((state) => state.bill.billService);
  const { details } = useSelector((state) => state.bill.billItem);
  const { data: categoryServices } = useSelector(
    (state) => state.bill.categoryService
  );
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const isEdit = () => editMode === "edit";
  const method = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      name: isEdit() ? details.name : "",
      variationCode: isEdit() ? details.variation_code : "",
      amount: isEdit() ? details.variation_amount : "",
      serviceId: isEdit() ? details.service_id : "",
      billersServices: isEdit() ? details.billers_services : "",
      // categoryId:"timi"
    },
  });
  const { handleSubmit, reset, watch, getValues } = method;

  const onSubmit = (data) => {
    setLoading(true);
    const categoryId = modService(categoryServices).filter(
      (item) => item.value === getValues().serviceId
    );

    let payload = {
      name: data.name,
      variation_code: data.variationCode,
      variation_amount: unFormatNumber(data.amount),
      service_id: data.serviceId,
      fixedPrice: "yes",
      billers_services: data.billersServices,
      enable: true,
      category_id: categoryId[0].id,
    };
    if (editMode === "add") {
      onCreateBillItem(payload);
    } else {
      onUpdateBillItem(payload);
    }
  };

  const onCreateBillItem = (data) => {
    setLoading(true);
    dispatch(createBillItem(data))
      .unwrap()
      .then(() => {
        setLoading(false);
        notifySuccess("Bill Item created successfully");
        dispatch(getBillItems());
        reset();
        close();
      })
      .catch((err) => {
        setLoading(false);
        notifyError(
          err?.data?.response_message ||
            err?.response_message ||
            "Failed to create Bill Item"
        );
      });
  };
  const watchService = watch("billersServices");

  useEffect(() => {
    dispatch(getCategoryService(watchService));
  }, [watchService, dispatch]);

  const onUpdateBillItem = (data) => {
    const payload = {
      id: details.id,
      data,
    };
    dispatch(updateBillItem(payload))
      .unwrap()
      .then(() => {
        setLoading(false);
        notifySuccess("Bill Item updated successfully");
        dispatch(getBillItems());
        reset();
        close();
      })
      .catch((err) => {
        setLoading(false);
        notifyError(
          err?.data?.response_message ||
            err?.response_message ||
            "Failed to update Bill Item"
        );
      });
  };

  return (
    <Wrapper>
      <InnerWrapper>
        <DrawerHeader title={titles[editMode]} close={close} />
        <CustomInput method={method} label="Name *" name="name" />
        <CustomInput
          method={method}
          label="Variation Code *"
          name="variationCode"
        />
        <AmountInput method={method} label="Variation Amount *" name="amount" />

        <CustomSelect
          method={method}
          label="Billers Service *"
          options={modBillService(data)}
          name="billersServices"
        />
        <CustomSelect
          method={method}
          options={modService(categoryServices)}
          label="Bill Category *"
          name="serviceId"
        />
        {/* <CustomInput
          method={method}
          label="Category ID *"
          name="categoryId"
          value={getValues().categoryId}
          disabled
        /> */}
      </InnerWrapper>
      <BtnWrapper>
        <Button disabled={loading}>
          <button onClick={handleSubmit(onSubmit)}>
            {loading ? "Loading..." : btnTexts[editMode]}
          </button>
        </Button>
      </BtnWrapper>
    </Wrapper>
  );
};

export default AddEditBillItem;
