import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    status:'idle',
    drawer:'',
    sidenav:true,
    editMode:''
}
const actionSlice = createSlice({
    name:'action',
    initialState,
    reducers:{
        toggleDrawer(state,action){
            state.drawer =action.payload;
        },
        toggleSidenav(state,action){
            state.sidenav = action.payload;
        },
        setEditMode(state, action) {
            state.editMode = action.payload;
          },
    }
})
export const{toggleDrawer,toggleSidenav,setEditMode}= actionSlice.actions
export default actionSlice.reducer