import React, { useState } from "react";
import { Button, InputWrap } from "../../styles/DefaultStyles";
import DrawerHeader from "../drawer/DrawerHeader";
import { BtnWrapper, InnerWrapper, Wrapper } from "./form.styled";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch, useSelector } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import Switch from "../excerpts/Switch";
import CustomSelect from "../excerpts/Select";
import { disableCaregory } from "../../utils/mods";
import { notifyError, notifySuccess } from "../../utils/toast";
import {
  createEnableDIsableBillCategory,
  getBillServices,
} from "../../features/billSlice";
import CustomInput from "../excerpts/Input";
import { renderSourceDisable } from "./EnableDisableBillItem";

const schema = yup.object({
  id: yup.string().required(),
});

const EnableDisableBillCategory = ({ close }) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const { editMode } = useSelector((state) => state.action);
  const { details } = useSelector((state) => state.bill.billCategory);
  const isEdit = () => editMode === "edit";
  const method = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      id: isEdit() ? details.id : "",
      name: isEdit() ? details.name : "",
      enable: isEdit() ? details.enable : "",
    },
  });
  const { handleSubmit, reset } = method;
  const [activateCategory, setActivateCategory] = useState(
    details.enable ? true : false
  );
  const onSubmit = (data) => {
    setLoading(true);
    let payload = {
      category_id: data.id,
      enable: activateCategory,
    };
    onToggleBillCat(payload);
  };

  const onToggleBillCat = (data) => {
    setLoading(true);

    dispatch(createEnableDIsableBillCategory(data))
      .unwrap()
      .then(() => {
        setLoading(false);
        notifySuccess("updated");
        dispatch(getBillServices());
        reset();
        close();
      })
      .catch((err) => {
        setLoading(false);
        notifyError(
          err?.data?.response_message || err?.response_message || "Failed"
        );
      });
  };

  return (
    <Wrapper>
      <InnerWrapper>
        <DrawerHeader title="Enable/Disable" close={close} />
        <CustomInput method={method} label="ID *" disabled name="id" />
        <CustomInput method={method} label="Name *" disabled name="name" />
        <h4 style={{display:"flex",marginBottom:"1rem", gap:"2rem"}}>
          <span>Active?</span>
          <span>{renderSourceDisable(details.enable)}</span>
        </h4>
        <InputWrap>
          <label className="inline-label">
            <span>Activate?</span>
            <Switch
              checked={activateCategory}
              onChecked={() => setActivateCategory(!activateCategory)}
              name="enable/disable-bill-item"
            />
          </label>
        </InputWrap>
      </InnerWrapper>
      <BtnWrapper>
        <Button disabled={loading}>
          <button onClick={handleSubmit(onSubmit)}>
            {loading ? "Loading..." : "Submit"}
          </button>
        </Button>
      </BtnWrapper>
    </Wrapper>
  );
};

export default EnableDisableBillCategory;
