import { createColumnHelper } from "@tanstack/react-table";
import React from "react";
import { toggleDrawer } from "../../features/actionSlice";
import { setWalletSettlement } from "../../features/walletSlice";
import { useDispatch, useSelector } from "react-redux";
import Table from "../table/Table";
import DateTime from "../excerpts/DateTime";
import Amount from "../excerpts/Amount";

const WalletSettlement = () => {
  const columnHelper = createColumnHelper();
  const columns = [
    columnHelper.accessor("accountName", {
      cell: (info) => info.getValue(),
      header: () => <span>Name</span>,
    }),
    columnHelper.accessor("accountNumber", {
      cell: (info) => info.getValue(),
      header: () => <span>Account Number</span>,
    }),
    columnHelper.accessor("amount", {
      cell: (info) => <Amount amount={info.getValue()} />,
      header: () => <span>Amount</span>,
    }),
    columnHelper.accessor("clearingFeeAmount", {
      cell: (info) => <Amount amount={info.getValue()} />,
      header: () => <span>Fee</span>,
    }),
    columnHelper.accessor("paymentMethod", {
      cell: (info) => info.getValue(),
      header: () => <span>Method</span>,
    }),
    columnHelper.accessor("payerName", {
      cell: (info) => info.getValue(),
      header: () => <span>payer Name</span>,
    }),
    columnHelper.accessor("created_at", {
      cell: (info) => <DateTime date={info.getValue()} />,
      header: () => <span>Date</span>,
    }),
  ];

  const { data } = useSelector((state) => state.wallet.walletSettlement);
  const dispatch = useDispatch();
  const onClick = (data) => {
    dispatch(setWalletSettlement(data));
    dispatch(toggleDrawer("wallet_settlement"));
  };
  return (
    <div>
      <Table
        data={data}
        columns={columns}
        click={onClick}
        filter={"true"}
        filterParams={true}
        name={"Wallet Settlement"}
      />
    </div>
  );
};

export default WalletSettlement;
