import React from 'react'
import DateTime from '../excerpts/DateTime';
import Status from '../excerpts/Status';
import Amount from '../excerpts/Amount';
import { createColumnHelper } from '@tanstack/react-table';
import Table from '../table/Table';
import { useDispatch, useSelector } from 'react-redux';
import { setLatestTransactionDetails } from '../../features/transactionSlice';
import { toggleDrawer } from '../../features/actionSlice';

const LatestTransactions = () => {
  const columnHelper = createColumnHelper();
  const columns = [
    columnHelper.accessor("tx_ref", {
      cell: (info) => info.getValue(),
      header: () => <span>Ref</span>,
    }),
    columnHelper.accessor("email", {
      cell: (info) => info.getValue(),
      header: () => <span>Email</span>,
    }),
    columnHelper.accessor("amount", {
      cell: (info) => <Amount amount={info.getValue()} />,
      header: () => <span>Amount</span>,
    }),
    columnHelper.accessor("reasons", {
      cell: (info) => info.getValue(),
      header: () => <span>Payment For</span>,
    }),
    columnHelper.accessor("payment_channel", {
      cell: (info) => info.getValue(),
      header: () => <span>Payment Channel</span>,
    }),
    columnHelper.accessor("status", {
      cell: (info) => <Status status={info.getValue()}/>,
      header: () => <span>Status</span>,
    }),
    columnHelper.accessor("created_at", {
      cell: (info) =><DateTime val={info} date={info.getValue()} />,
      header: () => <span>Date</span>,
    }),
  ];
  const {data} = useSelector((state)=>state.transaction.latestTransactions)
  const dispatch = useDispatch()
  const onClick = (data) => {
    dispatch(setLatestTransactionDetails(data));
    dispatch(toggleDrawer('latest_transactions'))
  };
  return (
    <div>
      <Table
        data={data}
        columns={columns}
        click={onClick}
        filter={"true"}
        filterParams={true}
      />
    </div>
  )
}

export default LatestTransactions
