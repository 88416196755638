import React, { useState } from "react";
import AllTransactions from "../conponents/transactions/AllTransactions";
import SubTransactions from "../conponents/transactions/SubTransactions";
import BillTransactions from "../conponents/transactions/BillTransactions";
import Tab from "../conponents/tabs/Tab";
import LatestTransactions from "../conponents/transactions/LatestTransactions";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import {
  getAllTransactions,
  getBillSubscriptions,
  getBillTransactions,
} from "../features/transactionSlice";

const Transactions = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getBillSubscriptions());
    dispatch(getAllTransactions());
    dispatch(getBillTransactions());
  }, [dispatch]);
  const tabList = [
    { name: "All Transactions", alias: "all-transactions" },
    { name: "Bills Transactions", alias: "bills-transactions" },
    {name: "Bill Subscription Transaction ",alias: "subscription-transaction"},
    { name: "Latest Transactions", alias: "latest-transactions" },
  ];

  const [activeTab, setActiveTab] = useState("all-transactions");
  return (
    <div>
      <div style={{ margin: "2rem 0" }}>
        <h1>Transaction</h1>
      </div>
      <Tab tabs={tabList} activeTab={activeTab} setActiveTab={setActiveTab} />
      <div>
        {activeTab === tabList[0].alias && <AllTransactions />}
        {activeTab === tabList[1].alias && <BillTransactions />}
        {activeTab === tabList[2].alias && <SubTransactions />}
        {activeTab === tabList[3].alias && <LatestTransactions />}
      </div>
    </div>
  );
};

export default Transactions;
