import React from "react";
import Table from "../table/Table";
import { toggleDrawer } from "../../features/actionSlice";
import { setWalletTransaction } from "../../features/walletSlice";
import { useDispatch, useSelector } from "react-redux";
import DateTime from "../excerpts/DateTime";
import Amount from "../excerpts/Amount";
import { createColumnHelper } from "@tanstack/react-table";
import Status from "../excerpts/Status";

const WalletTransactions = () => {
  const columnHelper = createColumnHelper();
  const columns = [
    columnHelper.accessor("transaction_ref", {
      cell: (info) => info.getValue(),
      header: () => <span>Ref</span>,
    }),
    columnHelper.accessor("currency", {
      cell: (info) => info.getValue(),
      header: () => <span>Currency</span>,
    }),
    columnHelper.accessor("amount", {
      cell: (info) => <Amount amount={info.getValue()} />,
      header: () => <span>Amount</span>,
    }),
    columnHelper.accessor("prev_balance", {
      cell: (info) => <Amount amount={info.getValue()} />,
      header: () => <span>Previous Balance</span>,
    }),
    columnHelper.accessor("current_balance", {
      cell: (info) => <Amount amount={info.getValue()} />,
      header: () => <span>Current Balance</span>,
    }),

    columnHelper.accessor("status", {
      cell: (info) =><Status status={info.getValue()} />,
      header: () => <span>Status</span>,
    }),
    columnHelper.accessor("created_at", {
      cell: (info) => <DateTime date={info.getValue()} />,
      header: () => <span>Date</span>,
    }),
  ];

  const { data } = useSelector((state) => state.wallet.walletTransactions);
  const dispatch = useDispatch();
  const onClick = (data) => {
    dispatch(setWalletTransaction(data));
    dispatch(toggleDrawer("wallet_transaction"));
  };
  return (
    <div>
      <Table
        data={data}
        columns={columns}
        click={onClick}
        filter={"true"}
        filterParams={true}
        name={"Wallet Transactions"}
      />
    </div>
  );
};

export default WalletTransactions;
