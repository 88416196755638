

export const toastSuccessConfig = {
  pauseOnFocusLoss: false,
  pauseOnHover: false,
  hideProgressBar: true,
};

export const toastErrorConfig = {
  pauseOnFocusLoss: false,
  pauseOnHover: false,
  hideProgressBar: true,
};

// 🦄
