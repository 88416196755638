import React from "react";
import { Link} from "react-router-dom";
import styled from "styled-components";
import Logo from "../../icons/Paymoni";

const Wrapper = styled.div`
  left: 0;
  width: 20%;
  background-color: #f5f5f5;
  height: 100vh;
  overflow-y: auto;
`;
const SLogo = styled.div`
  width: 60%;
  margin: 1rem 0 0 2rem;
`;
const Items = styled.div`
  display: flex;
  font-size: 20px;
  font-weight: 500;
  text-decoration: none !important;
  justify-content: center;
  align-items: center;
  padding: 5px 0;
`;
const Button = styled.div`
  display: flex;
  width: 70%;
  justify-content: center;
  height: 40px;
  background-color: white;
  margin: 2rem auto;
  border-radius: 10px;
`;

const SideNav = () => {
  return (
    <Wrapper>
      <SLogo>
        <Logo />
      </SLogo>{" "}
      <Button>
        <Link style={{ textDecoration: "none" }} to="/">
          <Items>Home </Items>
        </Link>
      </Button>
      {/* <Button>
        <Link style={{ textDecoration: "none" }} to="/virtual-card">
          <Items >Virtual card </Items>
        </Link>
      </Button> */}
      {/* <Button>
        <Link style={{ textDecoration: "none" }} to="/bill-payment">
          <Items >Bill Payment</Items>
        </Link>
      </Button> */}
      <Button>
        <Link style={{ textDecoration: "none" }} to="/transactions">
          <Items>Transactions</Items>
        </Link>
      </Button>
      <Button>
        <Link style={{ textDecoration: "none" }} to="/Users">
          <Items>Users</Items>
        </Link>
      </Button>
      <Button>
        <Link style={{ textDecoration: "none" }} to="/bill-setting">
          <Items>Bills Settings</Items>
        </Link>
      </Button>
      <Button>
        <Link style={{ textDecoration: "none" }} to="/wallet">
          <Items>Wallet</Items>
        </Link>
      </Button>
      <Button>
        <Link style={{ textDecoration: "none" }} to="/email">
          <Items>Email</Items>
        </Link>
      </Button>
      <Button>
        <Link style={{ textDecoration: "none" }} to="/blogs">
          <Items>Blogs</Items>
        </Link>
      </Button>
      <Button>
        <Link style={{ textDecoration: "none" }} to="/annoucements">
          <Items>Annoucements</Items>
        </Link>
      </Button>
      {/* <Button>
        <Link style={{ textDecoration: "none" }} to="/schedule-payment">
          <Items >Schedule Payments</Items>
        </Link>
      </Button> */}
      {/* <Button>
        <Link style={{ textDecoration: "none" }} to="/foreign-accounts">
          <Items >Foreign Accounts</Items>
        </Link>
      </Button> */}
      {/* <Button>
        <Link style={{ textDecoration: "none" }} to="/manage-expenses">
          <Items >Manage Expenses</Items>
        </Link>
      </Button> */}
    </Wrapper>
  );
};

export default SideNav;
