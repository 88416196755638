import React from 'react';
import { HeaderWrapper } from './Drawer.styled';

const DrawerHeader = ({ title, tag, close }) => {
  return (
    <HeaderWrapper>
      <div className="dh-title">
        <h2>{title}</h2>
        <p>{tag}</p>
      </div>
      <div className="dh-close" onClick={close}>
        &times;
      </div>
    </HeaderWrapper>
  );
};

export default DrawerHeader;
