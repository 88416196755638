import React from "react";
import DrawerHeader from "../../drawer/DrawerHeader";
import { InnerWrapper, TrDetail, Wrapper } from ".././form.styled";
import Amount from "../../excerpts/Amount";
import { useSelector } from "react-redux";
import DateTime from "../../excerpts/DateTime";

const BillSubTransactionDetails = ({ close, data }) => {
  const { details } = useSelector(
    (state) => state.transaction.billSubscriptions
  );
  return (
    <Wrapper>
      <InnerWrapper>
        <DrawerHeader
          title="Transaction Details"
          tag="view Transaction"
          close={close}
        />
        <div>
          <TrDetail>
            <span className="tr-title">Product Name</span>
            <span className="tr-data">{details.product_name}</span>
          </TrDetail>
          <TrDetail>
            <span className="tr-title">Email</span>
            <span className="tr-data">{details.email}</span>
          </TrDetail>
          <TrDetail>
            <span className="tr-title">Phone</span>
            <span className="tr-data">{details.phone}</span>
          </TrDetail>
          <TrDetail>
            <span className="tr-title">Id</span>
            <span className="tr-data">{details.requestId}</span>
          </TrDetail>

          <TrDetail>
            <span className="tr-title">Narration</span>
            <span className="tr-data">{details.narration}</span>
          </TrDetail>
          <TrDetail>
            <span className="tr-title">Amount</span>
            <span className="tr-data">
              <Amount amount={details.amount} />
            </span>
          </TrDetail>
          <TrDetail>
            <span className="tr-title">Status</span>
            <span className="tr-data">{details.status}</span>
          </TrDetail>
          <TrDetail>
            <span className="tr-title">Date</span>
            <span className="tr-data">
              <DateTime date={details.created_at} />
            </span>
          </TrDetail>
          <TrDetail>
            <span className="tr-title">Convience Fee</span>
            <span className="tr-data">{details.convinience_fee}</span>
          </TrDetail>
          <TrDetail>
            <span className="tr-title">Service Code</span>
            <span className="tr-data">{details.services_code}</span>
          </TrDetail>
        </div>
      </InnerWrapper>
    </Wrapper>
  );
};

export default BillSubTransactionDetails;
