import React from 'react'

const SchedulePayment = () => {
  return (
    <div>
      schedule payment
      to pay
    </div>
  )
}

export default SchedulePayment
