

const Amount = ({ amount }) => {
  return (
    <span style={{ display: 'flex', alignItems: 'center' }}>
      <span style={{ fontFamily: 'Arial', marginRight: '5px' }}>₦</span>{' '}
      {numberWithCommas(Number(amount).toFixed(2))}
    </span>
  );
};
export default Amount

export const Figures = ({ amount }) => {
  return (
    <span style={{ display: 'flex', alignItems: 'center' }}>
      <span style={{ fontFamily: 'Arial', marginRight: '5px' }}></span>{' '}
      {numberWithCommas(Number(amount))}
    </span>
  );
};


export const numberWithCommas = (x) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  // return x.replace(/\B(?=(?:\d{3})+(?!\d))/g, ',');
};

export const AmountFormated = ({ amount }) => {
  return (
    <span style={{ display: 'flex', alignItems: 'center' }}>
      <span style={{ fontFamily: 'Arial', marginRight: '5px' }}>₦</span>{' '}
      {amount}
    </span>
  );
};