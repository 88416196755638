import React from 'react'

const ForeignAccounts = () => {
  return (
    <div>
      foreign account
    </div>
  )
}

export default ForeignAccounts
