import React from 'react'
import styled from 'styled-components'
import { Stats } from '../../conponents/stats/Stats'
import { ListStats } from '../../conponents/stats/ListStats'

export const Wrapper =styled.div`
background-color:green;
`
const Dashboard = () => {
  return (
    <div>
      <Stats/>
      <ListStats/>
    </div>
  )
}

export default Dashboard
