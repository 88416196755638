import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { Figures } from "../excerpts/Amount";
export const Wrapper = styled.div`
  margin: 3rem 0;
`;
export const FinCard = styled.div`
  background-color: ${(props) => props.color};
  border-radius: 20px;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 120px;
  border: 1px solid ${(props) => props.color};

  .fin_stat-icon {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
export const FinStatGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));

  gap: 20px;
`;
export const Heading = styled.h2`
  font-family: "Recoleta";
  font-size: 20px;
  margin-bottom: 20px;
`;

export const FinCardInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const CardName = styled.p`
  color: #ff7c44;
  font-size: 17px;
  font-weight: 500;
  padding-top: 20px;
`;

export const CardTotal = styled.h2`
  font-family: "Recoleta";
`;

export const Stats = () => {
  const { stats } = useSelector((state) => state.stat);
  const TabList = [
    {
      name: "Total Number of Users",
      //   icon: <HandoutIcon />,
      total: stats.no_of_users,
      color: "#E9E9E9",
    },
    {
      name: "Total transactions",
      //   icon: <HandoutIcon />,
      currency: "N",
      total: stats.volume_of_transactions,
      color: "#E9E9E9",
    },
    {
      name: "Number of Wallets",
      //   icon: <HandoutIcon />,
      total: stats?.no_of_wallet,
      color: "#E9E9E9",
    },
    {
      name: "Verified Users",
      //   icon: <HandoutIcon />,
      total: stats?.verify_users,
      color: "#E9E9E9",
    },
    {
      name: "Unverified Users",
      //   icon: <HandoutIcon />,

      total: stats?.un_verify_users,
      color: "#E9E9E9",
    },
    {
      name: "Numbers of Transactions",
      //   icon: <HandoutIcon />,
      total: stats?.no_of_transactions,
      color: "#E9E9E9",
    },
    {
      name: "Successful Transactions",
      //   icon: <HandoutIcon />,
      currency: "N",
      total: stats?.successful_transactions,
      color: "#E9E9E9",
    },
    {
      name: "Unsuccessful Transactions",
      //   icon: <HandoutIcon />,
      currency: "N",
      total: stats?.un_successful_transactions,
      color: "#E9E9E9",
    },
  ];

  return (
    <div>
      <CardTotal>FInacial Statisitics</CardTotal>
      <Wrapper>
        <FinStatGrid>
          {TabList.map((stat, index) => (
            <div key={index}>
              <FinCard key={stat.color} color={stat.color}>
                <FinCardInfo>
                  <CardName>{stat.name}</CardName>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <span style={{ fontWeight: "bold", fontSize: "23px" }}>
                      {stat.currency}
                    </span>
                    <CardTotal>
                      <Figures amount={stat.total || "00"} />
                    </CardTotal>
                  </div>
                </FinCardInfo>
                <span className="fin_stat-icon">{stat.icon}</span>
              </FinCard>
            </div>
          ))}
        </FinStatGrid>
      </Wrapper>
    </div>
  );
};
