import React, {  useState } from 'react';
import FIlterTable from './FIlterTable';
import {
  TableHead,
  TableWrapper,
   
  FilterParams,
} from './table.styled';
import {
  flexRender,
  getCoreRowModel,
  useReactTable,
  getPaginationRowModel,
  getFilteredRowModel,
} from '@tanstack/react-table';
import { rankItem } from '@tanstack/match-sorter-utils';
import Pagination from './Pagination';
// import FilterIcon from '../icons/FilterIcon';

const fuzzyFilter = (row, columnId, value, addMeta) => {
  // Rank the item
  const itemRank = rankItem(row.getValue(columnId), value);

  // Store the itemRank info
  addMeta({
    itemRank,
  });

  // Return if the item should be filtered in/out
  return itemRank.passed;
};

const Table = ({ data, columns, click, name, children, filter, filterParams }) => {
 
  const [columnFilters, setColumnFilters] = useState([]);
  const [globalFilter, setGlobalFilter] = useState('');
  const table = useReactTable({
    data,
    columns,
    filterFns: {
      fuzzy: fuzzyFilter,
    },
    state: {
      globalFilter,
      columnFilters,
    },
    onGlobalFilterChange: setGlobalFilter,
    onColumnFiltersChange: setColumnFilters,
    globalFilterFn: fuzzyFilter,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
  });
  return (
    <>
      <TableHead filterParams={filterParams}>
        <div className="search-filter">
          {filterParams && (
            <div className="table-filter">
              <FilterParams>
                <p>{name}</p>
                <div className="colored pl flex">
                  <div>
                    {' '}
                    {/* <FilterIcon />{' '} */}
                  </div>
                  <p> Filtered By: Date </p>
                </div>
              </FilterParams>
            </div>
          )}

          {filter && (
            <div className="table-search">
              <FIlterTable
                value={globalFilter ?? ''}
                onChange={(value) => setGlobalFilter(value)}
              />
            </div>
          )}
        </div>
        <div className="children">{children}</div>
      </TableHead>

      <TableWrapper>
        <table>
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th key={header.id}>
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          {click ? (
            <tbody>
              {table.getRowModel().rows.map((row) => (
                <tr
                  className="table-click"
                  key={row.id}
                  onClick={() => click(row.original)}
                >
                  {row.getVisibleCells().map((cell) => (
                    <td key={cell.id}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          ) : (
            <tbody>
              {table.getRowModel().rows.map((row) => (
                <tr key={row.id}>
                  {row.getVisibleCells().map((cell) => (
                    <td key={cell.id}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          )}
        </table>
      </TableWrapper>

      <Pagination table={table} />
    </>
  );
};

export default Table;
