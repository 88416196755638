export const modCat = (data) =>
  data?.map((d) => ({
    value: `${d.id}`,
    label: d.name,
  }));

  export const modBillService = (data) =>
  data?.map((d) => ({
    value: d.name,
    label: d.display_name,
  }));
  
  export const modService = (data) =>
  data?.map((d) => ({
    value: `${d.serviceID}`,
    label: d.name,
    id:d.id,
  }));
  
  export const modBillItem = (data) =>
  data?.map((d) => ({
    value: `${d.id}`,
    label: d.name,
  }));

  export const modUser = (data) =>
  data?.map((d) => ({
    value: `${d.id}`,
    label: d.first_name,
  }));

  export const disableCaregory = (data) =>
  data?.map((d) => ({
    value: `${d.id}`,
    label: d.name,
  }));
  export const services = (data) =>
  data?.map((d) => ({
    value: d.id,
    label: d.description,
  }));

  export const servicesBiller = (data) =>
  data?.map((d) => ({
    id:`${ d.id}`,
    value: d.name,
    label: d.description,
  }));
  export const email = (data) =>
  data?.map((d) => ({
    value: `${d.name}`,
    label: d.name,
  }));

  export const blogs = (data) =>
  data?.map((d) => ({
    value: d.id,
    label: d.name,
  }));
  
  export const slug = (data) =>
  data?.map((d) => ({
    value: d.blog_slug,
    label: d.title,
  }));