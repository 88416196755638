import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { FileUploader } from 'react-drag-drop-files';
import styled from 'styled-components';
import { ButtonDangerLight } from '../../styles/DefaultStyles';
import ErrorMsg from '../alert/ErrorMsg';
import { notifyError } from '../../utils/toast';

const ImageUpload = ({ name, method, type = 'png' }) => {
  const [image, setImage] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [imageUrl, setImageUrl] = useState('');

  const {
    setError,
    setValue,
    getValues,
    clearErrors,
    formState: { errors },
  } = method;

  useEffect(() => {
    const image = getValues(name);
    if (image) {
      setImageUrl(image);
    }
  }, [getValues(name)]);

  useEffect(() => {
    if (image) {
      handleUpload();
    }
  }, [image]);

  const handleChange = (file) => {
    setImage(file);
  };

  const handleUpload = async () => {
    clearErrors(`${name}`);
    setUploadProgress(1);

    const formData = new FormData();
    formData.append('file', image);
    formData.append('upload_preset', process.env.REACT_APP_PRESET_NAME);
    formData.append('cloud_name', process.env.REACT_APP_CLOUD_NAME);

    try {
      const response = await axios.post(
        'https://api.cloudinary.com/v1_1/dieu9c5zg/image/upload',
        formData,
        {
          onUploadProgress: (progressEvent) =>
            setUploadProgress(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            ),
        }
      );
      setImageUrl(response.data.secure_url);
      setValue(name, response.data.secure_url);
    } catch (error) {
      notifyError('Something went wrong.');
    }
  };

  const handleDelete = async () => {
    setImageUrl('');
    setImage(null);
    setUploadProgress(0);
    // setValue(name, '');
  };

  const handleTypeError = (error) => {
    setError(name, { message: error });
  };

  const handleSizeError = (error) => {
    setError(name, { message: error });
  };

  return (
    <>
      {imageUrl ? (
        <Preview image={imageUrl} onDelete={handleDelete} type={type} />
      ) : (
        <FileUploader
          handleChange={handleChange}
          name="file"
          types={[type || 'PNG']}
          maxSize={1}
          onTypeError={handleTypeError}
          onSizeError={handleSizeError}
        >
          <Upload image={image} progress={uploadProgress} type={type} />
        </FileUploader>
      )}
      {errors?.[name] && <ErrorMsg msg={errors?.[name].message} />}
    </>
  );
};

const Upload = ({ progress, image, type }) => {
  return (
    <UploadWrap disabled={progress > 0}>
      <p>
        Click to upload document. Max file <span>5MB</span> and{' '}
        <span>{type.toUpperCase()}</span> only
      </p>
      {image && <progress value={progress} max={100} />}
    </UploadWrap>
  );
};

const Preview = ({ image, onDelete, type }) => {
  return (
    <PreviewWrap>
      {type === 'pdf' ? (
        <a
          title="Click to view document"
          className="pdf-link"
          target="_blank"
          rel='noreferrer'
          href={image}
        >
          {image}
        </a>
      ) : (
        <img src={image} alt='no img' />
      )}
      <ButtonDangerLight small>
        <button onClick={onDelete}>Remove</button>
      </ButtonDangerLight>
    </PreviewWrap>
  );
};

export default ImageUpload;

const UploadWrap = styled.div`
  width: 100%;
  min-height: 100px;
  border: 2px dashed ${(props) => props.theme.accent};
  background-color: ${(props) => props.theme.accent_light};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: ${(props) => props.theme.radius1};
  cursor: pointer;
  pointer-events: ${(props) => (props.disabled ? 'default' : 'not-allowed')};
  opacity: ${(props) => (props.disabled ? '0.5' : 1)};

  p {
    color: ${(props) => props.theme.color2};
    width: 300px;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 1.5;

    button {
      border: none;
      margin-top: 10px;
      border: 1px solid ${(props) => props.theme.danger};
      padding: 5px 20px;
      border-radius: 5px;
      background-color: transparent;
      font-size: 14px;
      color: ${(props) => props.theme.danger};
      cursor: pointer;

      :hover {
        background-color: ${(props) => props.theme.danger_light};
      }
    }

    span {
      font-weight: 600;
      color: ${(props) => props.theme.accent};
    }
  }
`;

const PreviewWrap = styled.div`
  display: flex;
  margin-left: 50px;
  align-items: center;
  gap: 30px;

  img {
    width: 80px;
    height: 80px;
    border-radius: 5px;
    object-fit: cover;
  }
  a {
    background-color: ${(props) => props.theme.accent_light};
    padding: 20px;
    border-radius: 5px;
    cursor: pointer;
    width: 300px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;
