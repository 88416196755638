import { createColumnHelper } from "@tanstack/react-table";
import React from "react";
import { setEditMode, toggleDrawer } from "../../features/actionSlice";
import { useDispatch, useSelector } from "react-redux";
import Table from "../table/Table";
import DateTime from "../excerpts/DateTime";
import Amount from "../excerpts/Amount";
import { ButtonLight } from "../../styles/DefaultStyles";
import { oneBillItem, setBillItem } from "../../features/billSlice";

const BillService = () => {
  const columnHelper = createColumnHelper();
  const columns = [
    columnHelper.accessor("name", {
      cell: (info) => info.getValue(),
      header: () => <span>Name</span>,
    }),
    columnHelper.accessor("billers_services", {
      cell: (info) => info.getValue(),
      header: () => <span>Service</span>,
    }),
   
    columnHelper.accessor("variation_amount", {
      cell: (info) => <Amount amount={info.getValue()} />,
      header: () => <span>Amount</span>,
    }),

    columnHelper.accessor("created_at", {
      cell: (info) => <DateTime date={info.getValue()} />,
      header: () => <span>Date</span>,
    }),
    columnHelper.accessor("action", {
      cell: (info) => (
        <ButtonLight small>
          <button
            onClick={(e) => {
              e.stopPropagation();
              onEnable(info.row.original.id);
            }}
          >
            Enable/Disable
          </button>
        </ButtonLight>
      ),
      header: () => <span>Action</span>,
    }),
    columnHelper.accessor("action", {
      cell: (info) => (
        <ButtonLight small>
          <button
            onClick={(e) => {
              e.stopPropagation();
              onEdit(info.row.original.id);
            }}
          >
            Edit Details
          </button>
        </ButtonLight>
      ),
      header: () => <span>Action</span>,
    }),
  ];

  const { data } = useSelector((state) => state.bill.billItem);

  const dispatch = useDispatch();
  const onClick = (data) => {
    dispatch(oneBillItem(data));
    dispatch(toggleDrawer("bill_item"));
  };
  const handleClick=()=>{
    dispatch(toggleDrawer('add_edit_bill_item'));
    dispatch(setEditMode("add"));
    
  }
  const onEdit =(id)=>{
    dispatch(toggleDrawer('add_edit_bill_item'));
    dispatch(setEditMode("edit"));
    dispatch(setBillItem(id))
  }
  const onEnable= (id)=>{
    dispatch(toggleDrawer('enable_disable_bill_item'))
    dispatch(setEditMode("edit"));
    dispatch(setBillItem(id));
  }

  return (
    <div>
      <div style={{display:"flex", justifyContent:"flex-end"}}>
        <ButtonLight onClick={handleClick}>
          <button>Create Bill Item</button>
        </ButtonLight>
      </div>
      <Table
        data={data}
        columns={columns}
        click={onClick}
        filter={"true"}
        filterParams={true}
        name={"Bill Service"}
      />
    </div>
  );
};

export default BillService;
