import React from "react";
import DrawerHeader from "../drawer/DrawerHeader";
import { InnerWrapper, TrDetail, Wrapper } from "./form.styled";
import { useSelector } from "react-redux";
import DateTime from "../excerpts/DateTime";

export function renderSource(value) {
  if (value === false || null || 0) {
    return "No";
  } else {
    return "Yes";
  }
}
const EmailDetails = ({ close, data }) => {
    const Body = (value) => {
        return <div dangerouslySetInnerHTML={{ __html: value }} />;
      };
      const source = (value) => {
        if (value === "ALL_USER") return "All Users";
      };
  const { email } = useSelector((state) => state.email);
 
  return (
    <Wrapper>
      <InnerWrapper>
        <DrawerHeader title="Email Details" tag="view email" close={close} />
        <div>
          <TrDetail>
            <span className="tr-title">Subject</span>
            <span className="tr-data">{email.subject}</span>
          </TrDetail>
          <TrDetail>
            <span className="tr-title">Message</span>
            <span className="tr-data">{Body(email.message)}</span>
          </TrDetail>
          <TrDetail>
            <span className="tr-title">Category</span>
            <span className="tr-data">{source(email.user_category)}</span>
          </TrDetail>
          <TrDetail>
            <span className="tr-title">Date</span>
            <span className="tr-data">
              <DateTime date={email.created_at} />
            </span>
          </TrDetail>
        </div>
      </InnerWrapper>
    </Wrapper>
  );
};

export default EmailDetails;
