import React, { useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import styled from "styled-components";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import CustomSelect from "../excerpts/Select";
import { email } from "../../utils/mods";
import CustomInput from "../excerpts/Input";
import { createEmail, getEmails } from "../../features/emailSlice";
import { BtnWrapper } from "../forms/form.styled";
import { Button } from "../../styles/DefaultStyles";
// import { notifyError, notifySuccess } from "../../utils/Toast";
export const Wrapper1 = styled.div`
  margin: 2rem auto;
  width: 70%;
`;

const schema = yup.object({
  subject: yup.string().required(),
  userCategory:yup.string().required(),
});
function CreateEmail({close}) {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const { category } = useSelector((state) => state.email);
  const method = useForm({
    resolver: yupResolver(schema),
  });
  const { handleSubmit, reset } = method;
 

 //text to html
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const onEditorStateChange = (editorState) => {
    setEditorState(editorState);
  };
   
 
  const onSubmit = (data) => {
    setLoading(true);
    let payload = {
      subject: data.subject,
      user_category: data.userCategory,
      message:draftToHtml(convertToRaw(editorState.getCurrentContent()))
    };
    onToggleEmail(payload);
  };

  const onToggleEmail = (data) => {
    setLoading(true);
    dispatch(createEmail(data))
      .unwrap()
      .then(() => {
        setLoading(false);
        // notifySuccess("Email Created succesfully");
        alert("Email Added successfully");
        dispatch(getEmails());
        reset();
        close();
      })
      .catch((err) => {
        setLoading(false);
        // notifyError(
        //   err?.data?.response_message || err?.response_message || "Failed"
        // );
        alert("Email not added successfully");
      });
  };


  return (
    <Wrapper1>
      <h2 style={{ margin: "1rem", display: "flex", justifyContent: "center" }}>
        Send Email
      </h2>
      <CustomInput method={method} label="Subject *" name="subject" />
      <CustomSelect
          method={method}
          label="User Category"
          options={email(category)}
          name="userCategory"
        />
      <div style={{border:"1px solid grey"}}>
        <Editor
          editorState={editorState}
          toolbarClassName="toolbarClassName"
          wrapperClassName="wrapperClassName"
          editorClassName="editorClassName"
          onEditorStateChange={onEditorStateChange}
        />
      </div>
      {/* <textarea
        disabled
        row="7"
        style={{width: '100%',height:"60px",resize:"none"}}
        value={draftToHtml(convertToRaw(editorState.getCurrentContent()))}
      /> */}
      <BtnWrapper>
        <Button disabled={loading}>
          <button onClick={handleSubmit(onSubmit)}>
            {loading ? "Loading..." : "Submit"}
          </button>
        </Button>
      </BtnWrapper>
    </Wrapper1>
  );
}

export default CreateEmail;
