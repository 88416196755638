import React from "react";
import DrawerHeader from "../drawer/DrawerHeader";
import { InnerWrapper, TrDetail, Wrapper } from "./form.styled";
import { useSelector } from "react-redux";
import DateTime from "../excerpts/DateTime";

export function renderSource(value) {
  if (value === false || null || 0) {
    return "No";
  } else {
    return "Yes";
  }
}
const UserDetails = ({ close, data }) => {
 
  const { user } = useSelector((state) => state.user);
 
  return (
    <Wrapper>
      <InnerWrapper>
        <DrawerHeader title="User Details" tag="view user" close={close} />
        <div>
          <TrDetail>
            <span className="tr-title">Name</span>
            <span className="tr-data">{user.first_name}</span>
          </TrDetail>
          <TrDetail>
            <span className="tr-title">Email</span>
            <span className="tr-data">{user.email}</span>
          </TrDetail>
          <TrDetail>
            <span className="tr-title">Phone Number</span>
            <span className="tr-data">{user.phone_number}</span>
          </TrDetail>
          <TrDetail>
            <span className="tr-title">Kyc Level</span>
            <span className="tr-data">{user.kyc_level}</span>
          </TrDetail>
          <TrDetail>
            <span className="tr-title">locked Status</span>
            <span className="tr-data">{renderSource(user.locked_status)}</span>
          </TrDetail>
          <TrDetail>
            <span className="tr-title">Verified Bvn</span>
            <span className="tr-data">{renderSource(user.verify_bvn)}</span>
          </TrDetail>
          <TrDetail>
            <span className="tr-title">Account verified</span>
            <span className="tr-data">{renderSource(user.account_verify)}</span>
          </TrDetail>
          <TrDetail>
            <span className="tr-title">Card Activated</span>
            <span className="tr-data">{renderSource(user.card_activate)}</span>
          </TrDetail>
          <TrDetail>
            <span className="tr-title">Last Seen</span>
            <span className="tr-data">{user.last_login}</span>
          </TrDetail>
          <TrDetail>
            <span className="tr-title">Date</span>
            <span className="tr-data">
              <DateTime date={user.created_at} />
            </span>
          </TrDetail>
        </div>
      </InnerWrapper>
    </Wrapper>
  );
};

export default UserDetails;
