import React from "react";

function Logo() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="188"
      height="39"
      fill="none"
      viewBox="0 0 188 39"
    >
      <path
        fill="#212135"
        d="M52.976 30v-5.408h5.856c5.088 0 8.288-2.944 8.288-8.032s-3.2-8-8.288-8H46.96V30h6.016zm5.184-16.032c2.112 0 2.976.416 2.976 2.592 0 2.208-.864 2.624-2.976 2.624h-5.184v-5.216h5.184zM73.614 30.32c3.487 0 5.471-1.44 6.08-3.712h.32V30h5.632v-9.184c0-4.512-2.72-7.264-8.353-7.264-5.472 0-9.056 2.752-9.056 7.04v.064h6.017v-.128c0-1.344.608-1.728 2.591-1.728 2.145 0 2.849.256 2.849 1.888v.32l-6.56.64c-3.648.352-5.216 1.984-5.216 4.256 0 2.752 2.047 4.416 5.695 4.416zm.352-4.8c0-.352.224-.544.768-.608l4.928-.576c-.16 1.44-1.345 1.792-4.257 1.792-.927 0-1.44-.096-1.44-.608zm17.015 9.92c4.704 0 7.168-.992 8.896-4.8l7.648-16.768h-6.72l-2.4 6.272-1.056 3.776h-.416l-1.152-3.712-2.784-6.336h-6.848l7.712 15.936c-.256.128-.64.192-1.12.192h-4.672v5.44h2.912zM114.195 30v-8.32c0-2.08.512-2.752 2.976-2.752 2.496 0 3.136.704 3.136 2.944V30h6.016v-8.32c0-2.08.512-2.752 2.976-2.752 2.496 0 3.136.704 3.136 2.944V30h6.016V19.664c0-3.488-1.952-6.112-6.08-6.112-4.192 0-5.728 2.528-6.08 5.216h-.32c-.32-2.912-2.144-5.216-5.952-5.216-4.064 0-5.536 2.528-5.888 5.216h-.32v-4.896h-5.632V30h6.016zm34.974.32c5.6 0 9.408-3.168 9.408-8.384 0-5.248-3.808-8.384-9.408-8.384s-9.408 3.136-9.408 8.384c0 5.216 3.808 8.384 9.408 8.384zm0-5.312c-2.72 0-3.456-.736-3.456-3.072 0-2.336.736-3.104 3.456-3.104 2.72 0 3.456.768 3.456 3.104 0 2.336-.736 3.072-3.456 3.072zM166.039 30v-8.32c0-2.08.64-2.752 3.296-2.752 2.624 0 3.456.704 3.456 2.944V30h6.016V19.664c0-3.488-2.048-6.112-6.4-6.112-4.32 0-6.08 2.528-6.432 5.216h-.32v-4.896h-5.632V30h6.016zm20.562-17.6V8.56h-6.016v3.84h6.016zm0 17.6V13.872h-6.016V30h6.016z"
      ></path>
      <path
        fill="#FF7C44"
        fillRule="evenodd"
        d="M16.5 36C2.912 36 0 33.088 0 19.5 0 5.912 2.912 3 16.5 3 30.088 3 33 5.912 33 19.5 33 33.088 30.088 36 16.5 36zM9.528 22.757a1.375 1.375 0 01-2.557-1.013l1.279.506c1.279.506 1.278.507 1.278.507zM8.25 22.25l1.279.506.005-.014a6.735 6.735 0 01.141-.326c.103-.227.255-.548.451-.913.4-.746.944-1.606 1.568-2.247.65-.666 1.17-.88 1.553-.855.348.023 1.089.283 2.098 1.845 1.212 1.875 2.613 2.99 4.228 3.097 1.581.104 2.845-.8 3.703-1.68.883-.907 1.569-2.023 2.021-2.867a17.852 17.852 0 00.676-1.406l.04-.093.01-.028.004-.008v-.003s.002-.002-1.277-.508l1.279.506a1.375 1.375 0 00-2.557-1.013v.001l-.006.014a4.113 4.113 0 01-.027.065 15.16 15.16 0 01-.565 1.174c-.4.746-.944 1.606-1.568 2.247-.65.666-1.17.88-1.553.855-.348-.023-1.088-.283-2.098-1.845-1.212-1.875-2.613-2.99-4.228-3.097-1.581-.104-2.845.8-3.703 1.68-.883.907-1.569 2.023-2.021 2.867a17.849 17.849 0 00-.715 1.5l-.011.027-.004.008v.003s-.002.002 1.277.508z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default Logo;