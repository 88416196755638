import React, { useEffect, useState } from "react";
import Tab from "../conponents/tabs/Tab";
import CreateEmail from "../conponents/email/CreateEmail";
import AllEmails from "../conponents/email/AllEmails";
import { useDispatch } from "react-redux";
import { getEmailUsers, getEmails } from "../features/emailSlice";

const Transactions = () => {
  const dispatch= useDispatch();
  useEffect(()=>{
    dispatch(getEmails());
    dispatch(getEmailUsers());
  },[dispatch])
  const tabList = [
    { name: "Create Email", alias: "create-email" },
    { name: "All Emails", alias: "all-emails" },
  ];

  const [activeTab, setActiveTab] = useState("create-email");
  return (
    <div>
      <div style={{ margin: "2rem 0" }}>
        <h1>Emails</h1>
      </div>
      <Tab tabs={tabList} activeTab={activeTab} setActiveTab={setActiveTab} />
      <div>
        {activeTab === tabList[0].alias && <CreateEmail />}
        {activeTab === tabList[1].alias && <AllEmails />}
      </div>
    </div>
  );
};

export default Transactions;
