import styled from 'styled-components';

export const TabWrapper = styled.div`
  display: flex;
  margin-bottom: 30px;

  .activeTab {
    font-weight: 600;
    position: relative;
    

    ::before {
      background-color:#FF7C44;
    }
  }
`;
export const TabItem = styled.div`
  font-size: 18px;
  font-family: 'Recoleta';
  margin-right: 40px;
  cursor: pointer;
  position: relative;

  ::before {
    content: '';
    width: 100%;
    height: 2px;
    background-color: transparent;
    position: absolute;
    bottom: 0;
  }
`;
