import { createColumnHelper } from "@tanstack/react-table";
import React from "react";
import { setEditMode, toggleDrawer } from "../../features/actionSlice";
import { useDispatch, useSelector } from "react-redux";
import Table from "../table/Table";
import DateTime from "../excerpts/DateTime";
import Amount from "../excerpts/Amount";
import { ButtonLight } from "../../styles/DefaultStyles";
import { oneBillCategory, setBillCategory } from "../../features/billSlice";

const BillService = () => {
  const columnHelper = createColumnHelper();
  const columns = [
    columnHelper.accessor("serviceID", {
      cell: (info) => info.getValue(),
      header: () => <span>Service</span>,
    }),
    columnHelper.accessor("name", {
      cell: (info) => info.getValue(),
      header: () => <span>Name</span>,
    }),
    columnHelper.accessor("commissions", {
      cell: (info) => <Amount amount={info.getValue()} />,
      header: () => <span>Ledger Balance</span>,
    }),
    columnHelper.accessor("maximum_amount", {
      cell: (info) => <Amount amount={info.getValue()} />,
      header: () => <span>Max Amount</span>,
    }),

    columnHelper.accessor("created_at", {
      cell: (info) => <DateTime date={info.getValue()} />,
      header: () => <span>Date</span>,
    }),
    columnHelper.accessor("action", {
      cell: (info) => (
        <ButtonLight small>
          <button
            onClick={(e) => {
              e.stopPropagation();
              onEnable(info.row.original.id);
            }}
          >
            Enable/Disable
          </button>
        </ButtonLight>
      ),
      header: () => <span>Action</span>,
    }),
    columnHelper.accessor("action", {
      cell: (info) => (
        <ButtonLight small>
          <button
            onClick={(e) => {
              e.stopPropagation();
              editCategory(info.row.original.id);
            }}
          >
            Edit Details
          </button>
        </ButtonLight>
      ),
      header: () => <span>Action</span>,
    }),
  ];

  const { data } = useSelector((state) => state.bill.billCategory);

  const dispatch = useDispatch();
  const onClick = (data) => {
    dispatch(oneBillCategory(data));
    dispatch(toggleDrawer("bill_category"));
  };
  function addCategory() {
    dispatch(toggleDrawer("add_edit_bill_category"));
    dispatch(setEditMode("add"));
  };
  function editCategory(id) {
    dispatch(toggleDrawer("add_edit_bill_category"));
    dispatch(setEditMode("edit"));
    dispatch(setBillCategory(id));

  };
  function onEnable(id){
    dispatch(toggleDrawer('enable_disable_bill_category'));
    dispatch(setEditMode("edit"));
    dispatch(setBillCategory(id));
  }
  return (
    <div>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <ButtonLight onClick={addCategory}>
          <button>Create Bill Category</button>
        </ButtonLight>
      </div>
      <Table
        data={data}
        columns={columns}
        click={onClick}
        filter={"true"}
        filterParams={true}
        name={"Bill Service"}
      />
    </div>
  );
};

export default BillService;
