import styled from 'styled-components';

export const Wrapper = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: ${(props) => (props.visible ? '1' : '0')};
  visibility: ${(props) => (props.visible ? 'visible' : 'hidden')};
  transition: all 300ms;
  z-index: 99999;
`;
export const Main = styled.div`
  position: absolute;
  width: 450px;
  height: 100%;
  background-color: #fff;
  z-index: 998;
  right: 0;
  margin-right: ${(props) => (props.visible ? '0px' : '-400px')};
  transition: all 300ms;
  display: flex;

  @media (max-width: 500px) {
    width: 100%;
  }
`;
export const Overlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
`;

export const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;

  .dh-title {
    h2 {
      color: black;
      font-family: 'Recoleta';
      font-size: 25px;
    }
    p {
      color: black;
    }
  }

  .dh-close {
    width: 40px;
    height: 40px;
    background-color:white
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 30px;
    cursor: pointer;
  }
`;
