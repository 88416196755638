import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BASE_URL } from "../utils/constants";
import { api } from "../service/axiosinstance";
import axios from "axios";

const initialState = {
  satus: "loading",
  billCategory: {
    status: "loading",
    data: [],
    details: {},
  },
  billItem: {
    status: "loading",
    data: [],
    details: {},
  },
  billService: {
    status: "loading",
    data: [],
    details: null,
  },
  switchCode: {
    status: "loading",
    data: [],
    details: null,
  },
  categoryService: {
    status: "loading",
    data: [],
    details: null,
  },
};

//Bill Items
export const createBillItem = createAsyncThunk(
  "bill/createBillItem",
  async (payload, thunkAPI) => {
    try {
      const { data } = await api.post(
        `${BASE_URL}/admin/create_items`,
        payload
      );
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response);
    }
  }
);

export const createEnableDIsableBillItem = createAsyncThunk(
  "bill/createEnableDisableBillItem",
  async (payload, thunkAPI) => {
    try {
      const { data } = await axios.post(
        `${BASE_URL}/admin/enable_or_disable_items`,
        payload
      );
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response);
    }
  }
);
export const updateBillItem = createAsyncThunk(
  "bill/updateBillItem",
  async (payload, thunkAPI) => {
    try {
      const { data } = await axios.put(
        `${BASE_URL}/admin/update_items/${payload.id}`,
        payload.data
      );
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response);
    }
  }
);

export const getBillItems = createAsyncThunk(
  "bill/getBillItems",
  async (_, thunkAPI) => {
    try {
      const { data } = await api.get(`${BASE_URL}/admin/get_bills_items`);
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response);
    }
  }
);

// Bill Category
export const getBillCategory = createAsyncThunk(
  "bill/getBillcategory",
  async (_, thunkAPI) => {
    try {
      const { data } = await api.get(`${BASE_URL}/admin/get_bills_category`);
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response);
    }
  }
);
export const createBillCategory = createAsyncThunk(
  "bill/createBillICategory",
  async (payload, thunkAPI) => {
    try {
      const { data } = await axios.post(
        `${BASE_URL}/admin/create_category`,
        payload
      );
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response);
    }
  }
);

export const createEnableDIsableBillCategory = createAsyncThunk(
  "bill/createEnableDisableBillCategory",
  async (payload, thunkAPI) => {
    try {
      const { data } = await api.post(
        `${BASE_URL}/admin/enable_or_disable_bills_category`,
        payload
      );
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response);
    }
  }
);

export const updateBillCategory = createAsyncThunk(
  "bill/updateBillCategory",
  async (payload, thunkAPI) => {
    try {
      const { data } = await axios.put(
        `${BASE_URL}/admin/update_category/${payload.id}`,
        payload.data
      );
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response);
    }
  }
);

//Bill Service
export const getBillServices = createAsyncThunk(
  "bill/getBillServices",
  async (_, thunkAPI) => {
    try {
      const { data } = await api.get(`${BASE_URL}/admin/get_bills_services`);
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response);
    }
  }
);

// Switch code
export const getSwitchCode = createAsyncThunk(
  "bill/getSwitchCode",
  async (_, thunkAPI) => {
    try {
      const { data } = await api.get(`${BASE_URL}/admin/get_switch_code`);

      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response);
    }
  }
);

// Category Under Service
export const getCategoryService = createAsyncThunk(
  "bill/getCategoryService",
  async (payload, thunkAPI) => {
    try {
      const { data } = await api.get(
        `${BASE_URL}/admin/category_under_service/${payload}`
      );
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response);
    }
  }
);

const billSlice = createSlice({
  name: "bill",
  initialState,
  reducers: {
    oneBillItem(state, action) {
      state.billItem.details = action.payload;
    },
    setBillItem(state, action) {
      const id = action.payload;
      state.billItem.details = state.billItem.data.find(
        (details) => details.id === id
      );
    },
    oneBillCategory(state, action) {
      state.billCategory.details = action.payload;
    },
    setBillCategory(state, action) {
      const id = action.payload;
      state.billCategory.details = state.billCategory.data.find(
        (details) => details.id === id
      );
    },
    oneBillService(state, action) {
      state.billService.details = action.payload;
    },
    oneSwitchCode(state, action) {
      state.switchCode.details = action.payload;
    },
  },
  extraReducers: {
    //Bill Item
    [getBillItems.pending]: (state) => {
      state.billItem.status = "loading";
    },
    [getBillItems.fulfilled]: (state, actions) => {
      state.billItem.status = "idle";
      state.billItem.data = actions.payload.data;
    },
    [getBillItems.rejected]: (state) => {
      state.billItem.status = "idle";
    },
    //update Bill Item

    [updateBillItem.pending]: (state) => {
      state.billItem.status = "loading";
    },
    [updateBillItem.fulfilled]: (state) => {
      state.billItem.status = "idle";
    },
    [updateBillItem.rejected]: (state) => {
      state.billItem.status = "idle";
    },

    // Bill Category
    [getBillCategory.pending]: (state) => {
      state.billCategory.status = "loading";
    },
    [getBillCategory.fulfilled]: (state, actions) => {
      state.billCategory.status = "idle";
      state.billCategory.data = actions.payload.data;
    },
    [getBillCategory.rejected]: (state) => {
      state.billCatgory.status = "idle";
    },
    //update Bill Category

    [updateBillCategory.pending]: (state) => {
      state.billCategory.status = "loading";
    },
    [updateBillCategory.fulfilled]: (state) => {
      state.billCategory.status = "idle";
    },
    [updateBillCategory.rejected]: (state) => {
      state.billCategory.status = "idle";
    },

    //Bill Services
    [getBillServices.pending]: (state) => {
      state.billService.status = "loading";
    },
    [getBillServices.fulfilled]: (state, actions) => {
      state.billService.status = "idle";
      state.billService.data = actions.payload.data;
    },
    [getBillServices.rejected]: (state) => {
      state.billService.status = "idle";
    },

    //swtich code
    [getSwitchCode.pending]: (state) => {
      state.switchCode.status = "loading";
    },
    [getSwitchCode.fulfilled]: (state, action) => {
      state.switchCode.status = "idle";
      state.switchCode.data = action.payload.data;
    },
    [getSwitchCode.rejected]: (state) => {
      state.switchCode.status = "idle";
    },

    //category Service
    [getCategoryService.pending]: (state) => {
      state.categoryService.status = "loading";
    },
    [getCategoryService.fulfilled]: (state, action) => {
      state.categoryService.status = "idle";
      state.categoryService.data = action.payload.data;
    },
    [getSwitchCode.rejected]: (state) => {
      state.categoryService.status = "idle";
    },
  },
});
export const {
  oneBillItem,
  setBillItem,
  oneBillCategory,
  setBillCategory,
  oneBillService,
  oneSwitchCode,
} = billSlice.actions;
export default billSlice.reducer;
