import React from "react";
import styled from "styled-components";
import LatestTransactions from "../transactions/LatestTransactions";
 
const Wrapper = styled.div`
  display: flex;
`;
const Container2 = styled.div`
  flex: 1;
`;

export const ListStats = () => {
 

  return (
    <Wrapper>
      {/* <Container1>
        <h3>All users</h3>
        <SubCont>
          <Users/>
        </SubCont>
      </Container1> */}
      <Container2>
        <h3>All transactions</h3>
       
        <LatestTransactions />
      </Container2>
    </Wrapper>
  );
};
