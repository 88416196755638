import React, { useState } from "react";
import { Button, InputWrap } from "../../styles/DefaultStyles";
import DrawerHeader from "../drawer/DrawerHeader";
import { BtnWrapper, InnerWrapper, Wrapper } from "./form.styled";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch, useSelector } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import { unFormatNumber } from "../../utils/numbers";
import CustomInput from "../excerpts/Input";
import AmountInput from "../excerpts/AmountInput";
import {
  createBillCategory,
  getBillCategory,
  getCategoryService,
  updateBillCategory, 
} from "../../features/billSlice";
import {
  
  modService,
  services,
  servicesBiller,
} from "../../utils/mods";
import CustomSelect from "../excerpts/Select";
import { notifyError, notifySuccess } from "../../utils/toast";
import ImageUpload from "../excerpts/UploadFIle";
import { useEffect } from "react";

const schema = yup.object({
  name: yup.string().required(),
  serviceId: yup.string().required(),
  miniumAmount: yup.string().required(),
  maximumAmount: yup.string().required(),
  productType: yup.string().required(),
  billersService: yup.string().required(),
  moreInfo: yup.string().required(),
  convenienceFee: yup.string().required(),
  merchantConvinienceFee: yup.string(),
  providerId: yup.string().required(),
  commission: yup.string().required(),
  photo: yup.string().url().nullable(),
});

const titles = { add: "Add New bill Category", edit: "Edit bill Category" };
const btnTexts = { add: "Create Bill Category", edit: "Update bill Category" };

const AddEditBillCategory = ({ close }) => {
  const { editMode } = useSelector((state) => state.action);
  const { data: billService } = useSelector((state) => state.bill.billService);
  const { data: categoryServices } = useSelector(
    (state) => state.bill.categoryService
  );
  const {   details } = useSelector(
    (state) => state.bill.billCategory
  );
  
  const { providers } = useSelector((state) => state.provider);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const type = [
    { label: "Flexible", value: "flexible" },
    { label: "Fixed", value: "fix" },
  ];

  const isEdit = () => editMode === "edit";

  const method = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      name: isEdit() ? details.name : "",
      serviceId: isEdit() ? details.serviceID : "",
      miniumAmount: isEdit() ? details.minimium_amount : "",
      maximumAmount: isEdit() ? details.maximum_amount : "",
      productType: isEdit() ? details.product_type : "",
      billersService: isEdit() ? details.billers_services : "",
      moreInfo: isEdit() ? details.more_info : "",
      convenienceFee: isEdit() ? details.convinience_fee : "",
      merchantConvinienceFee: isEdit() ? details.merchant_convinience_fee : "",
      servicesId: isEdit() ? details.services_id : "",
      providerId: isEdit() ? details.provider_id : "",
      commission: isEdit() ? details.commissions : "",
    },
  });

  const { handleSubmit, reset, watch, getValues } = method;

  const onSubmit = (data) => {
    const servicesId = servicesBiller(billService).filter(
      (item) => item.value === getValues().billersService
    );
    console.log(servicesId,'bill')
    setLoading(true);
    let payload = {
      name: data.name,
      serviceID: data.serviceId,
      minimium_amount: unFormatNumber(data.miniumAmount),
      maximum_amount: unFormatNumber(data.maximumAmount),
      product_type: data.productType,
      image: data.photo,
      convinience_fee: unFormatNumber(data.convenienceFee),
      commissions: unFormatNumber(data.commission),
      billers_services: data.billersService,
      merchant_convinience_fee: unFormatNumber(data.merchantConvinienceFee),
      more_info: data.moreInfo,
      services_id: servicesId[0].id,
      provider_id: data.providerId,
      enable: true,
    };
    console.log(payload)
    if (editMode === "add") {
      onCreateBillItem(payload);
    } else {
      onUpdateBillItem(payload);
    }
  };

  const onCreateBillItem = (data) => {
    dispatch(createBillCategory(data))
      .unwrap()
      .then(() => {
        setLoading(false);
        notifySuccess("Bill Category created successfully");
        dispatch(getBillCategory());
        reset();
        close();
      })
      .catch((err) => {
        setLoading(false);
        notifyError(
          err?.data?.response_message ||
            err?.response_message ||
            "Failed to create Bill Category"
        );
      });
  };

  const onUpdateBillItem = (data) => {
    const payload = {
      id: details.id,
      data,
    };
    dispatch(updateBillCategory(payload))
      .unwrap()
      .then(() => {
        setLoading(false);
        notifySuccess("Bill Category updated successfully");
        dispatch(getBillCategory());
        reset();
        close();
      })
      .catch((err) => {
        setLoading(false);
        notifyError(
          err?.data?.response_message ||
            err?.response_message ||
            "Failed to update Bill Category"
        );
      });
  };
  const watchService = watch("billersService");

  useEffect(() => {
    dispatch(getCategoryService(watchService));
  }, [watchService, dispatch]);

  return (
    <Wrapper>
      <InnerWrapper>
        <DrawerHeader title={titles[editMode]} close={close} />
        <CustomInput method={method} label="Name *" name="name" />
        <CustomInput method={method} label="Service ID *" name="serviceId" />

        <AmountInput
          method={method}
          label="Minimun Amount *"
          name="miniumAmount"
        />
        <AmountInput
          method={method}
          label="Maximum Amount *"
          name="maximumAmount"
        />
        <AmountInput
          method={method}
          label="Convenience Fee *"
          name="convenienceFee"
        />
        <AmountInput method={method} label="Commission *" name="commission" />
        <AmountInput
          method={method}
          label="Merchant Convience Fee *"
          name="merchantConvinienceFee"
        />
        <CustomSelect
          method={method}
          options={type}
          label="Product Type *"
          name="productType"
        />
        <CustomSelect
          method={method}
          options={services(providers)}
          label="Provider Id *"
          name="providerId"
        />
        <CustomSelect
          method={method}
          label="Billers Service *"
          options={servicesBiller(billService)}
          name="billersService"
        />
        {/* <CustomSelect
          method={method}
          options={modService(categoryServices)}
          label="Services Id *"
          name="servicesId"
        /> */}

        <CustomInput method={method} label="More Info *" name="moreInfo" />
        <InputWrap>
          <label>Upload Reciept</label>
          <ImageUpload method={method} name="photo" type="png" />
        </InputWrap>
      </InnerWrapper>
      <BtnWrapper>
        <Button disabled={loading}>
          <button onClick={handleSubmit(onSubmit)}>
            {loading ? "Loading..." : btnTexts[editMode]}
          </button>
        </Button>
      </BtnWrapper>
    </Wrapper>
  );
};

export default AddEditBillCategory;
