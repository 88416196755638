
import { Route, Routes } from "react-router-dom";
import VirtualCard from "./pages/VirtualCard";
import BillPayment from "./pages/BillPayment";
import SchedulePayment from "./pages/SchedulePayment";
import ForeignAccounts from "./pages/ForeignAccounts";
import ManageExpenses from "./pages/ManageExpenses";
import Transactions from "./pages/Transactions";
import Users from "./pages/Users";
import Wallet from "./pages/Wallet";
import BillSettings from "./pages/BillSettings";
import Dashboard from "./pages/dashboard/Dashboard";
import Layout from "./pages/dashboard/Layout";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { getAllUsers } from "./features/userSlice";
import { getStatistics } from "./features/statSlice";
import {getLatestTransactions} from "./features/transactionSlice";
import Email from "./pages/Email";
import Blogs from "./pages/Blogs";
import Announcement from "./conponents/annoucement/Announcement";

function App() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAllUsers());
    dispatch(getStatistics());
    dispatch(getLatestTransactions());
  }, [dispatch]);


  useEffect(()=>{
    // if(){

    // }
  },[])
  return (
    <>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index exact element={<Dashboard />} />
          <Route path="virtual-card" element={<VirtualCard />} />
          <Route path="bill-payment" element={<BillPayment />} />
          <Route path="manage-expenses" element={<ManageExpenses />} />
          <Route path="schedule-payment" element={<SchedulePayment />} />
          <Route path="foreign-accounts" element={<ForeignAccounts />} />
          <Route path="transactions" element={<Transactions />} />
          <Route path="users" element={<Users />} />
          <Route path="wallet" element={<Wallet />} />
          <Route path="bill-setting" element={<BillSettings />} />
          <Route path='email' element ={<Email/>}/>
          <Route path='Blogs' element ={< Blogs/>}/>
          <Route path='annoucements' element ={< Announcement/>}/>
        </Route>
      </Routes>
    </>
  );
}

export default App;
