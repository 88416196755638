import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { BASE_URL } from "../utils/constants";
import { api } from "../service/axiosinstance";

const initialState = {
  status: "loading",
  stats: {},
};

export const getStatistics = createAsyncThunk(
  "stat/getStatistics",
  async (_, thunkAPI) => {
    try {
      const { data } = await api.get(`${BASE_URL}/admin/dashabord_analysis`);
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response);
    }
  }
);

const statSlice = createSlice({
  name: "stat",
  initialState,
  reducers: {},
  extraReducers: {
    [getStatistics.pending]: (state) => {
      state.status = "loading";
    },
    [getStatistics.fulfilled]: (state, action) => {
      state.status = "idle";
      state.stats = action.payload.data;
    },
    [getStatistics.rejected]: (state) => {
      state.status = "idle";
    },
  },
});

export default statSlice.reducer;
