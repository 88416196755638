import styled from 'styled-components';

export const Button = styled.div`
  button {
    width: ${(props) => (props.fill ? '100%' : 'auto')};
    background-color: ${(props) => props.theme.accent};
    border: none;
    outline: none;
    color: ${(props) => props.theme.color4};
    padding: ${(props) => (props.small ? '10px 10px' : '12px 20px')};
    font-size: ${(props) => (props.small ? '14px' : '16px')};
    border-radius: 4px;
    cursor: pointer;
    border: 1px solid ${(props) => props.theme.accent};
    transition: ${(props) => props.theme.transition1};
    line-height: normal;
    min-width: 80px;
    opacity: ${(props) => (props.disabled ? '0.5' : '1')};
    pointer-events: ${(props) => (props.disabled ? 'none' : 'all')};

    :hover {
      background-color: ${(props) => props.theme.accent2};
    }
  }
`;

export const ButtonLight = styled(Button)`
  button {
    background-color: blue
    border: 1px solid ${(props) => props.theme.accent_light};
    color:white

    :hover {
      background-color: red;
    }
  }
`;

export const ButtonClear = styled(Button)`
  button {
    background-color: transparent;
    border: 1px solid ${(props) => props.theme.accent};
    color: ${(props) => props.theme.accent};

    :hover {
      background-color: ${(props) => props.theme.accent};
      color: ${(props) => props.theme.color4};
    }
  }
`;

export const InputGroup = styled.div`
  width: 100%;
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
`;

export const InputWrap = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  position: relative;

  input,
  select,
  textarea {
    width: 100%;
    border: 1px solid #bbb;
    height: 45px;
    border-radius: 4px;
    padding: 10px 10px;
    outline-color: rgb(0, 0, 230);
    background-color: white;
    font-size: 16px;
    color: ${(props) => props.theme.color1};
    font-weight: 400;
  }

  input[disabled] {
    background-color: #f2f2f2;
    cursor: not-allowed;
    border: 1px solid #ddd;
    color: #888;
  }

  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iNyIgdmlld0JveD0iMCAwIDEyIDciIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik01LjkyNDI2IDQuNTIwOUw5LjYzNzcgMC4yNTI4MDhMMTEuMTM3NyAxLjM1MjkxTDUuOTI0MjYgNi45NTkzTDAuNDM3MDEyIDEuNDcyMDVMMS42NTYyMSAwLjI1Mjg0OUw1LjkyNDI2IDQuNTIwOVoiIGZpbGw9IiNDNEM0QzQiLz4KPC9zdmc+Cg==')
      93% / 3% no-repeat;
  }

  label {
    color: ${(props) => props.theme.color2};
    margin-bottom: 10px;
  }

  .inline-label {
    display: flex;
    justify-content: space-between;
  }

  textarea {
    min-height: 80px;
    height: 100px;
    max-height: 200px;
    resize: vertical;
  }

  .select-beneficary {
    display: flex;
    justify-content: flex-end;

    span {
      color: ${(props) => props.theme.accent};
      padding: 5px;
      cursor: pointer;
      :hover {
        text-decoration: underline;
      }
    }
  }
`;

export const ButtonDangerLight = styled(Button)`
  button,
  a {
    background-color: ${(props) => props.theme.danger_light};
    border: 1px solid ${(props) => props.theme.danger_light};
    color: ${(props) => props.theme.danger};

    :hover {
      background-color: ${(props) => props.theme.danger_light2};
    }
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const Status = styled.div`
  width: 100px;
  text-align: center;
  padding: 6px 10px;
  border-radius: 15px;
  font-size: 14px;
  background-color: ${(props) => props.theme[props.status]};
  color: ${(props) => props.theme[props.status.toLowerCase() + '_dark']};
`;

export const Status2 = styled.div`
  width: 75px;
  font-weight: 400;
  color: ${(props) =>
    props.status === 'disabled' ? props.theme.danger : props.theme.green};
`;

export const Heading = styled.h2`
  font-family: 'Recoleta';
  font-size: 20px;
  margin-bottom: ${(props) => `${props.mb}px` || '20px'};
`;

export const NameImg = styled.div`
  display: flex;
  align-items: center;

  span {
    margin-left: 10px;
  }
`;

export const ButtonWrap = styled.div`
  display: flex;
  gap: 10px;
`;

export const InputSplit = styled.div`
  width: 100%;
  display: flex;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
`;
