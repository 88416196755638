import React, { useEffect, useState } from 'react';
import { IoEyeOffOutline, IoEyeOutline } from 'react-icons/io5';
import styled from 'styled-components';
import { InputWrap } from '../../styles/DefaultStyles';
import ErrorMsg from '../alert/ErrorMsg';

const CustomInput = ({ method, name, label, error, ...rest }) => {
  const [isPassword, setIsPassword] = useState(false);
  const {
    formState: { errors },
    register,
  } = method;

  useEffect(() => {
    if (rest.type === 'password') {
      setIsPassword(true);
    }
  }, [rest.type]);

  return (
    <InputWrap>
      <label>{label}</label>
      <input
        {...register(`${name}`)}
        {...rest}
        type={isPassword ? 'password' : 'text'}
      />
      {rest.type === 'password' && (
        <Eye onClick={() => setIsPassword(!isPassword)}>
          <span>{!isPassword ? <IoEyeOutline /> : <IoEyeOffOutline />}</span>
        </Eye>
      )}
      {errors[name] && <ErrorMsg msg={errors[name]?.message} />}
      {error && <ErrorMsg msg={error.message.split('.')[1]} />}
    </InputWrap>
  );
};

const Eye = styled.div`
  position: absolute;
  width: 45px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-left: none;
  font-size: 20px;
  top: 28px;
  right: 0;
  cursor: pointer;

  span {
    width: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-left: 1px solid #bbb;
    color: #777;

    :hover {
      color: #222;
    }
  }
`;

export default CustomInput;
