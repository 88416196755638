import React from 'react'
// import styled from 'styled-components'

// const Container =styled.div`
// background-color:green;
// `
const VirtualCard = () => {
  return (
    <div>
      virtual card
    </div>
  )
}

export default VirtualCard
