import React from "react";
import DrawerHeader from "../drawer/DrawerHeader";
import { InnerWrapper, TrDetail, Wrapper } from "./form.styled";
import { useSelector } from "react-redux";
import DateTime from "../excerpts/DateTime";
import { renderSource } from "./UserDetail";

const BlogPostDetails = ({ close, data }) => {
  const Body = (value) => {
    return <div dangerouslySetInnerHTML={{ __html: value }} />;
  };
  const { blog } = useSelector((state) => state.blog);

  return (
    <Wrapper>
      <InnerWrapper>
        <DrawerHeader
          title="Blog Post Details"
          tag="Blog Post email"
          close={close}
        />
        <div>
          <TrDetail>
            <span className="tr-title">Title</span>
            <span className="tr-data">{blog.title}</span>
          </TrDetail>
          <TrDetail>
            <span className="tr-title">Summary</span>
            <span className="tr-data">{blog.summary}</span>
          </TrDetail>
          <TrDetail>
            <span className="tr-title">Body</span>
            <span className="tr-data">{Body(blog.body_html)}</span>
          </TrDetail>
          <TrDetail>
            <span className="tr-title">Blog By</span>
            <span className="tr-data">{blog.blog_by}</span>
          </TrDetail>
          <TrDetail>
            <span className="tr-title">Reading Time</span>
            <span className="tr-data">{blog.reading_time}</span>
          </TrDetail>
          <TrDetail>
            <span className="tr-title">Blog Slug</span>
            <span className="tr-data">{blog.blog_slug}</span>
          </TrDetail>
          <TrDetail>
            <span className="tr-title">Status</span>
            <span className="tr-data">{renderSource(blog.status)}</span>
          </TrDetail>
          <TrDetail>
            <span className="tr-title">Date</span>
            <span className="tr-data">
              <DateTime date={blog.created_at} />
            </span>
          </TrDetail>
        </div>
      </InnerWrapper>
    </Wrapper>
  );
};

export default BlogPostDetails;
