import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api } from "../service/axiosinstance";
import { BASE_URL } from "../utils/constants";
const initialState = {
  status: "loading",
  announcement: {},
  announcements: [],
};

export const createAnnouncement = createAsyncThunk(
  "createAnnouncement/announcement",
  async (payload, thunkAPI) => {
    try {
      const { data } = await api.post(
        `${BASE_URL}/admin/create_announcement`,
        payload
      );
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response);
    }
  }
);
export const getAllAnnouncement = createAsyncThunk(
  "getAllAnnouncement/announcement",
  async (_, thunkAPI) => {
    try {
      const { data } = await api.get(`${BASE_URL}/admin/all_announcement`);
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response);
    }
  }
);
export const updateAnnouncement = createAsyncThunk(
  "UpdateAnnouncement/announcement",
  async (payload, thunkAPI) => {
    try {
      const { data } = await api.put(
        `${BASE_URL}/admin/update_announcement/${payload.id}`,
        payload.data
      );
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response);
    }
  }
);

const announcementSlice = createSlice({
  name: "announcement",
  initialState,
  reducers: {
    setAnnouncement(state, action){
      const id = action.payload;
      state.announcement = state.announcements.find(
        (data) => data.id === id
      );
    }
  },
  extraReducers: {
    [getAllAnnouncement.pending]: (state) => {
      state.status = "loading";
    },
    [getAllAnnouncement.fulfilled]: (state, action) => {
      state.status = "idle";
      state.announcements = action.payload.data;
    },
    [getAllAnnouncement.rejected]: (state) => {
      state.status = "idle";
    },

    [updateAnnouncement.pending]: (state) => {
      state.status = "loading";
    },
    [updateAnnouncement.fulfilled]: (state) => {
      state.status = "idle";
    },
    [updateAnnouncement.rejected]: (state) => {
      state.status = "idle";
    },
  },
});
export const{setAnnouncement}= announcementSlice.actions
export default announcementSlice.reducer;
