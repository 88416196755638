import React from "react";
import { useState } from "react";



 const defaultChartsToShow = 100;



const ReduceText = ({ text, chartsToShow = defaultChartsToShow }) => {

  const [expandText, setExpandText] = useState(false);
  const readMore = () => {
    setExpandText(!expandText);
  };
  return (
    <div>
      <div className={expandText ? "" : 'fadeout'}><div dangerouslySetInnerHTML={{ __html: text }} /></div>
      {text.length > chartsToShow && (
        <p role="button" onClick={readMore}>
          {expandText ? "View Less" : "View More"}
        </p>
      )}
    </div>
  );
};
 

export default ReduceText;
