import React from 'react';
import Drawer from './Drawer';
import { useDispatch, useSelector } from 'react-redux';
import { toggleDrawer } from '../../features/actionSlice';
import Pay from '../forms/Pay';
import UserDetails from '../forms/UserDetail';
import WalletDetails from '../forms/wallet/WalletDetails';
import AllTransactionDetails from '../forms/transactions/AllTransactionDetails';
import BillsTransactionDetails from '../forms/transactions/BillsTransactionDetails';
import BillSubTransactionDetails from '../forms/transactions/BillSubTransactionDetail';
import LatestTransactionDetails from '../forms/transactions/LatestTransactionDetails';
import WalletSettlementDetails from '../forms/wallet/WalletSettlementDetails';
import WalletTransactionDetails from '../forms/wallet/WalletTransactionDetails';
import BillCategoryDetails from '../forms/billSetting/BillCategoryDetails';
import BillItemDetails from '../forms/billSetting/BillItemDetails';
import SwitchCodeDetails from '../forms/billSetting/SwitchCodeDetails';
import AddEditBillItem from '../forms/AddEditBillItem';
import AddEditBillCategory from '../forms/AddEditBillCategory';
import EnableDisableBillItem from '../forms/EnableDisableBillItem';
import EnableDisableBillCategory from '../forms/EnableDisableBillCategory';
import EnableDisableUser from '../forms/EnableDisableUser';
import EmailDetails from '../forms/EmailDetails';
import AddEditAnnouncement from '../forms/AddEditAnnouncement';
import AddEditBlogPost from '../forms/AddEditBlogPost';
import AddEditBlogCategory from '../forms/AddEditBlogCategory';
import BlogPostDetails from '../forms/BlogPostDetails';

const DrawerMain = () => {
  const dispatch = useDispatch();
  const { drawer } = useSelector((state) => state.action);
  const onClose = () => {
    dispatch(toggleDrawer(''));
  };

  return (
    <Drawer visible={drawer !== ''} close={onClose}>
      {drawer === 'pay' && <Pay close={onClose} />}
      {drawer === 'user_detail' && <UserDetails close={onClose} />}
      {drawer === 'wallet_detail' && <WalletDetails close={onClose} />}
      {drawer === 'all_transactions' && <AllTransactionDetails close={onClose} />}
      {drawer === 'bills_transactions' && <BillsTransactionDetails close={onClose} />}
      {drawer === 'bill_sub_transactions' && <BillSubTransactionDetails close={onClose} />}
      {drawer === 'latest_transactions' && <LatestTransactionDetails close={onClose} />}
      {drawer === 'wallet_settlement' && <WalletSettlementDetails close={onClose} />}
      {drawer === 'wallet_transaction' && <WalletTransactionDetails close={onClose} />}
      {drawer === 'bill_category' && <BillCategoryDetails close={onClose} />}
      {drawer === 'bill_item' && <BillItemDetails close={onClose} />}
      {drawer === 'switch_code' && <SwitchCodeDetails close={onClose} />}
      {drawer === 'add_edit_bill_item' && <AddEditBillItem close={onClose} />}
      {drawer === 'add_edit_bill_category' && <AddEditBillCategory close={onClose} />}
      {drawer === 'enable_disable_bill_item' && <EnableDisableBillItem close={onClose} />}
      {drawer === 'enable_disable_bill_category' && <EnableDisableBillCategory close={onClose} />}
      {drawer === 'enable_disable_user' && <EnableDisableUser close={onClose} />}
      {drawer === 'email' && <EmailDetails close={onClose} />}
      {drawer === 'add_edit_announcement' && <AddEditAnnouncement close={onClose} />}
      {drawer === 'add_edit_blog_post' && <AddEditBlogPost close={onClose} />}
      {drawer === 'add_edit_blog_category' && <AddEditBlogCategory close={onClose} />}
      {drawer === 'blog_post_details' && <BlogPostDetails close={onClose} />}
    </Drawer>
  );
};

export default DrawerMain;
