import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api } from "../service/axiosinstance";
import { BASE_URL } from "../utils/constants";

const initialState = {
  status: "loading",
  providers: [],
};

export const getProviders = createAsyncThunk(
  "getProviders/provider",
  async (_, thunkAPI) => {
    try {
      const { data } = await api.get(`${BASE_URL}/admin/get_provider`);
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response);
    }
  }
);
const providerSlice = createSlice({
  name: "provider",
  initialState,
  reducers: {},
  extraReducers: {
    [getProviders.pending]: (state) => {
      state.status = "loading";
    },
    [getProviders.fulfilled]: (state, action) => {
      state.status = "idle";
      state.providers = action.payload.data;
    },
    [getProviders.rejected]: (state) => {
      state.status = "idle";
    },
  },
});

export default providerSlice.reducer;
