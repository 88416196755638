import React, { useState } from "react";
import { Button, InputWrap } from "../../styles/DefaultStyles";
import DrawerHeader from "../drawer/DrawerHeader";
import { BtnWrapper, InnerWrapper, Wrapper } from "./form.styled";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch, useSelector } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import Switch from "../excerpts/Switch";
import {
  createEnableDIsableBillItem,
  getBillItems,
} from "../../features/billSlice";
import { notifyError, notifySuccess } from "../../utils/toast";
import CustomInput from "../excerpts/Input";


const schema = yup.object({
  id: yup.string().required(),
});

 export function renderSourceDisable(value) {
  if (value === 0) {
    return "No";
  } else {
    return "Yes";
  }
}
const EnableDisableBillItem = ({ close }) => {
  const { details } = useSelector((state) => state.bill.billItem);
  const { editMode } = useSelector((state) => state.action);
  const [loading, setLoading] = useState(false);
  const [activateCategory, setActivateCategory] = useState(
    details.enable ? true : false
  );
  const dispatch = useDispatch();
  const isEdit = () => editMode === "edit";

  const method = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      id: isEdit() ? details.id : "",
      name: isEdit() ? details.name : "",
      enable: isEdit() ? details.enable : "",
    },
  });
  const { handleSubmit, reset} = method;

  const onSubmit = (data) => {
    setLoading(true);
    let payload = {
      items_id: data.id,
      enable: activateCategory,
    };
    onToggleBillItem(payload);
  };

  const onToggleBillItem = (data) => {
    setLoading(true);
    dispatch(createEnableDIsableBillItem(data))
      .unwrap()
      .then((res) => {
        setLoading(false);
        notifySuccess("updated");
        dispatch(getBillItems());
        reset();
        close();
      })
      .catch((err) => {
        setLoading(false);
        notifyError(
          err?.data?.response_message || err?.response_message || "Failed"
        );
      });
  };

  return (
    <Wrapper>
      <InnerWrapper>
        <DrawerHeader title="Enable/Disable" close={close} />
        <CustomInput method={method} label="ID *" disabled name="id" />
        <CustomInput method={method} label="Name *" disabled name="name" />
        <h4 style={{display:"flex",marginBottom:"1rem", gap:"2rem"}}>
          <span>Active?</span>
          <span>{renderSourceDisable(details.enable)}</span>
        </h4>

        <InputWrap>
          <label className="inline-label">
            <span>Activate?</span>
            <Switch
              checked={activateCategory}
              onChecked={() => setActivateCategory(!activateCategory)}
              name="enable"
            />
          </label>
        </InputWrap>
      </InnerWrapper>
      <BtnWrapper>
        <Button disabled={loading}>
          <button onClick={handleSubmit(onSubmit)}>
            {loading ? "Loading..." : "Submit"}
          </button>
        </Button>
      </BtnWrapper>
    </Wrapper>
  );
};

export default EnableDisableBillItem;
