import React, { useState } from "react";
import { Button } from "../../styles/DefaultStyles";
import DrawerHeader from "../drawer/DrawerHeader";
import { BtnWrapper, InnerWrapper, Wrapper } from "./form.styled";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch, useSelector } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import CustomInput from "../excerpts/Input";
import { notifyError, notifySuccess } from "../../utils/toast";
import { createAnnouncement, getAllAnnouncement, updateAnnouncement } from "../../features/announcementSlice";
import CustomSelect from "../excerpts/Select";
import { slug } from "../../utils/mods";

const schema = yup.object({
  title: yup.string().required(),
  description: yup.string().required(),
  blogSlug: yup.string().required(),
 
});

 
const titles = { add: "Add New Announcement", edit: "Edit Announcement" };
const btnTexts = { add: "Create Announcement", edit: "Update Announcement" };
const AddEditAnnouncement = ({ close }) => {
  const { editMode } = useSelector((state) => state.action);
  const { announcement } = useSelector((state) => state.announcement);
  const {blogs} =useSelector((state)=> state.blog)

  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const isEdit = () => editMode === "edit";
  const method = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      title: isEdit() ? announcement.title : "",
      description: isEdit() ? announcement.description : "",
      blogSlug: isEdit() ? announcement.blog_slug : "",
    },
  });
  const { handleSubmit, reset} = method;

  const onSubmit = (data) => {
    setLoading(true);
    let payload = {
      title: data.title,
      description: data.description,
      blog_slug:data.blogSlug,
      status: true,
    };

    if (editMode === "add") {
      onCreateAnnouncement(payload);
    } else {
      onUpdateAnnouncment(payload);
    }
  };

  const onCreateAnnouncement = (data) => {
    setLoading(true);
    dispatch(createAnnouncement(data))
      .unwrap()
      .then(() => {
        setLoading(false);
        notifySuccess("Announcement created successfully");
        dispatch(getAllAnnouncement());
        reset();
        close();
      })
      .catch((err) => {
        setLoading(false);
        notifyError(
          err?.data?.response_message ||
            err?.response_message ||
            "Failed to create Announcement"
        );
      });
  };
 


 
  const onUpdateAnnouncment = (data) => {
    const payload = {
      id: announcement.id,
      data,
    };
    dispatch(updateAnnouncement(payload))
      .unwrap()
      .then(() => {
        setLoading(false);
        notifySuccess("Announcement updated successfully");
        dispatch(getAllAnnouncement());
        reset();
        close();
      })
      .catch((err) => {
        setLoading(false);
        notifyError(
          err?.data?.response_message ||
            err?.response_message ||
            "Failed to update Announcement"
        );
      });
  };

  return (
    <Wrapper>
      <InnerWrapper>
        <DrawerHeader title={titles[editMode]}  close={close} />
        <CustomInput method={method} label="Title *" name="title" />
        <CustomInput method={method} label="Description *" name="description" />
        <CustomSelect options={slug(blogs)} method={method} label="Blog Slug *" name="blogSlug" />

      </InnerWrapper>
      <BtnWrapper>
        <Button disabled={loading}>
          <button onClick={handleSubmit(onSubmit)}>
            {loading ? "Loading..." : btnTexts[editMode]}
          </button>
        </Button>
      </BtnWrapper>
    </Wrapper>
  );
};

export default AddEditAnnouncement;
