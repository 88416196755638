import React, { useEffect } from 'react';
import {
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import { TabItem, TabWrapper } from './Tab.styled';

const Tab = ({ tabs, activeTab, setActiveTab }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const param = searchParams.get('tab');

  const onTab = (alias) => {
    setActiveTab(alias);
    navigate(`${location.pathname}?tab=${alias}`);
  };

  useEffect(() => {
    if (tabs.some((tab) => tab.alias === param)) {
      setActiveTab(param);
    } else {
      setActiveTab(tabs[0].alias);
    }
  }, [param]);

  return (
    <>
      <TabWrapper>
        {tabs.map((tab) => (
          <TabItem
            onClick={() => onTab(tab.alias)}
            key={tab.alias}
            className={activeTab === tab.alias ? 'activeTab' : ''}
          >
            {tab.name}
          </TabItem>
        ))}
      </TabWrapper>
    </>
  );
};

export default Tab;
