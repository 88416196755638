import React from "react";
import DrawerHeader from "../../drawer/DrawerHeader";
import { InnerWrapper, TrDetail, Wrapper } from "../form.styled";
import Amount from "../../excerpts/Amount";
import OnlyDate from "../../excerpts/OnlyDate";
import { useSelector } from "react-redux";

const WalletTransactionDetails = ({ close, data }) => {
  const { details } = useSelector((state) => state.wallet.walletTransactions);

  return (
    <Wrapper>
      <InnerWrapper>
        <DrawerHeader title="Wallet Details" tag="view Wallet" close={close} />
        <div>
          {/* <TrDetail>
            <span className="tr-title">Ref</span>
            <span className="tr-data">{details.transaction_ref}</span>
          </TrDetail> */}
          <TrDetail>
            <span className="tr-title">Reason</span>
            <span className="tr-data">{details.reason}</span>
          </TrDetail>
          <TrDetail>
            <span className="tr-title">Account Number</span>
            <span className="tr-data">{details.account_number}</span>
          </TrDetail>
          <TrDetail>
            <span className="tr-title">Wallet type</span>
            <span className="tr-data">{details.wallet_type}</span>
          </TrDetail>

          <TrDetail>
            <span className="tr-title">Amount</span>
            <span className="tr-data">
              <Amount amount={details.amount} />
            </span>
          </TrDetail>
          <TrDetail>
            <span className="tr-title">Previous Balance</span>
            <span className="tr-data">
              <Amount amount={details.prev_balance} />
            </span>
          </TrDetail>
          <TrDetail>
            <span className="tr-title">Current Balance</span>
            <span className="tr-data">
              <Amount amount={details.current_balance} />
            </span>
          </TrDetail>
          <TrDetail>
            <span className="tr-title">Date</span>
            <span className="tr-data">
              <OnlyDate date={details.created_at} />
            </span>
          </TrDetail>
          <TrDetail>
            <span className="tr-title">Status</span>
            <span className="tr-data">{details.status}</span>
          </TrDetail>
        </div>
      </InnerWrapper>
    </Wrapper>
  );
};

export default WalletTransactionDetails;
