import DateTime from "../excerpts/DateTime";
import Status from "../excerpts/Status";
import Amount from "../excerpts/Amount";
import { createColumnHelper } from "@tanstack/react-table";
import Table from "../table/Table";
import { useDispatch, useSelector } from "react-redux";
import { setAllTransactionDetails } from "../../features/transactionSlice";
import { toggleDrawer } from "../../features/actionSlice";

const AllTransactions = () => {
  const columnHelper = createColumnHelper();
  const columns = [
    columnHelper.accessor("tx_ref", {
      cell: (info) => info.getValue(),
      header: () => <span>Ref</span>,
    }),
    columnHelper.accessor("email", {
      cell: (info) => info.getValue(),
      header: () => <span>Email</span>,
    }),
    columnHelper.accessor("amount", {
      cell: (info) => <Amount amount={info.getValue()} />,
      header: () => <span>Amount</span>,
    }),
    columnHelper.accessor("reasons", {
      cell: (info) => info.getValue(),
      header: () => <span>Payment For</span>,
    }),
    columnHelper.accessor("payment_channel", {
      cell: (info) => info.getValue(),
      header: () => <span>Payment Channel</span>,
    }),
  
    columnHelper.accessor("status", {
      cell: (info) => <Status status={info.getValue()} />,
      header: () => <span>Status</span>,
    }),
    columnHelper.accessor("created_at", {
      cell: (info) => <DateTime val={info} date={info.getValue()} />,
      header: () => <span>Date</span>,
    }),
  ];
  const { transactions } = useSelector((state) => state.transaction);
  const dispatch = useDispatch();
  const onClick = (data) => {
    dispatch(setAllTransactionDetails(data));
    dispatch(toggleDrawer("all_transactions"));
  };
  return (
    <div>
      <Table
        data={transactions}
        columns={columns}
        click={onClick}
        filter={"true"}
        filterParams={true}
        name="All Transactions"
      />
    </div>
  );
};

export default AllTransactions;
