import { createColumnHelper } from "@tanstack/react-table";
import React from "react";
import { toggleDrawer } from "../../features/actionSlice";
import { useDispatch, useSelector } from "react-redux";
import Table from "../table/Table";
import DateTime from "../excerpts/DateTime";
import Amount from "../excerpts/Amount";
import { oneBillService } from "../../features/billSlice";
import { renderSource } from "../forms/UserDetail";

const BillService = () => {
  const columnHelper = createColumnHelper();
  const columns = [
    columnHelper.accessor("description", {
      cell: (info) => info.getValue(),
      header: () => <span>Description</span>,
    }),
    columnHelper.accessor("name", {
      cell: (info) => info.getValue(),
      header: () => <span>Name</span>,
    }),
    columnHelper.accessor("display_name", {
      cell: (info) => <Amount amount={info.getValue()} />,
      header: () => <span>Display Name</span>,
    }),
    columnHelper.accessor("enable", {
      cell: (info) => renderSource(info.getValue()),
      header: () => <span>Active?</span>,
    }),
 
    columnHelper.accessor("created_at", {
      cell: (info) => <DateTime date={info.getValue()} />,
      header: () => <span>Date</span>,
    }),
  ];

  const { data } = useSelector((state) => state.bill.billService);

  const dispatch = useDispatch();
  const onClick = (data) => {
    dispatch(oneBillService(data));
    dispatch(toggleDrawer("bill_category"));
  };
  return (
    <div>
      <Table
        data={data}
        columns={columns}
        click={onClick}
        filter={"true"}
        filterParams={true}
        name={"Bill Service"}
      />
    </div>
  );
};

export default BillService;
