import { createColumnHelper } from "@tanstack/react-table";
import React from "react";
import { toggleDrawer } from "../../features/actionSlice";
import { useDispatch, useSelector } from "react-redux";
import Table from "../table/Table";
import DateTime from "../excerpts/DateTime";
import { oneSwitchCode } from "../../features/billSlice";

const SwitchCode = () => {
  const columnHelper = createColumnHelper();
  const columns = [
    columnHelper.accessor("services_code_id", {
      cell: (info) => info.getValue(),
      header: () => <span>Service Code ID</span>,
    }),
    columnHelper.accessor("biller_category_code_id", {
      cell: (info) => info.getValue(),
      header: () => <span>Biller Category Code</span>,
    }),
    columnHelper.accessor("items_code_id", {
      cell: (info) => info.getValue(),
      header: () => <span>Items ID</span>,
    }),
    columnHelper.accessor("updated_at", {
      cell: (info) => <DateTime date={info.getValue()} />,
      header: () => <span>Updated Date</span>,
    }),
    
    columnHelper.accessor("created_at", {
      cell: (info) => <DateTime date={info.getValue()} />,
      header: () => <span>Created Date</span>,
    }),
    
  ];

  const { data } = useSelector((state) => state.bill.switchCode);

  const dispatch = useDispatch();
  const onClick = (data) => {
    dispatch(oneSwitchCode(data));
    dispatch(toggleDrawer("switch_code"));
  };
  return (
    <div>
      <Table
        data={data}
        columns={columns}
        click={onClick}
        filter={"true"}
        filterParams={true}
        name={"Switch Code"}
      />
    </div>
  );
};

export default SwitchCode;
