import React from "react";
import DrawerHeader from "../../drawer/DrawerHeader";
import { InnerWrapper, TrDetail, Wrapper } from ".././form.styled";
import Amount from "../../excerpts/Amount";
import OnlyDate from "../../excerpts/OnlyDate";

import { useSelector } from "react-redux";

const AllTransactionDetails = ({ close, data }) => {
  const { details } = useSelector((state) => state.transaction);
  return (
    <Wrapper>
      <InnerWrapper>
        <DrawerHeader title="Transaction Details" tag="view Transaction" close={close} />
        <div>
          <TrDetail>
            <span className="tr-title">Ref</span>
            <span className="tr-data">{details.tx_ref}</span>
          </TrDetail>
          <TrDetail>
            <span className="tr-title">Email</span>
            <span className="tr-data">{details.email}</span>
          </TrDetail>
          <TrDetail>
            <span className="tr-title">Payment For</span>
            <span className="tr-data">{details.reasons}</span>
          </TrDetail>
          <TrDetail>
            <span className="tr-title">Phone</span>
            <span className="tr-data">{details.phone}</span>
          </TrDetail>
          <TrDetail>
            <span className="tr-title">Name</span>
            <span className="tr-data">{details.fullName}</span>
          </TrDetail>
          <TrDetail>
            <span className="tr-title">Amount</span>
            <span className="tr-data"><Amount amount={details.amount}/></span>
          </TrDetail>
          <TrDetail>
            <span className="tr-title">Status</span>
            <span className="tr-data">{details.status}</span>
          </TrDetail>
          <TrDetail>
            <span className="tr-title">Date</span>
            <span className="tr-data">
              <OnlyDate date={details.created_at} />
            </span>
          </TrDetail>
          <TrDetail>
            <span className="tr-title">Transaction Type</span>
            <span className="tr-data">{details.transaction_type}</span>
          </TrDetail>
          <TrDetail>
            <span className="tr-title">Currency</span>
            <span className="tr-data">{details.currency}</span>
          </TrDetail>
        </div>
      </InnerWrapper>
    </Wrapper>
  );
};

export default AllTransactionDetails;
