import React from "react";
import DrawerHeader from "../../drawer/DrawerHeader";
import { InnerWrapper, TrDetail, Wrapper } from "../form.styled";
import Amount from "../../excerpts/Amount";
import OnlyDate from "../../excerpts/OnlyDate";
import { useSelector } from "react-redux";

const BillItemDetails = ({ close, data }) => {
  const renderSource = (value) => {
    if (value === 1) return "Yes";
    else return "No";
  };
  const { details } = useSelector((state) => state.bill.billItem);
  return (
    <Wrapper>
      <InnerWrapper>
        <DrawerHeader
          title="Bill Item Details"
          tag="view bill item"
          close={close}
        />
        <div>
          <TrDetail>
            <span className="tr-title">Name</span>
            <span className="tr-data">{details.name}</span>
          </TrDetail>
          <TrDetail>
            <span className="tr-title">Variation code</span>
            <span className="tr-data">{details.variation_code}</span>
          </TrDetail>
          <TrDetail>
            <span className="tr-title">Variation Amount</span>
            <span className="tr-data">
              <Amount amount={details.variation_amount} />
            </span>
          </TrDetail>
          <TrDetail>
            <span className="tr-title">Billers Service</span>
            <span className="tr-data">{details.billers_services}</span>
          </TrDetail>
          <TrDetail>
            <span className="tr-title">Date</span>
            <span className="tr-data">
              <OnlyDate date={details.created_at} />
            </span>
          </TrDetail>
          <TrDetail>
            <span className="tr-title">Fixed Price</span>
            <span className="tr-data">{details.fixedPrice}</span>
          </TrDetail>
          <TrDetail>
            <span className="tr-title">Active ?</span>
            <span className="tr-data">{renderSource(details.enable)}</span>
          </TrDetail>
        </div>
      </InnerWrapper>
    </Wrapper>
  );
};

export default BillItemDetails;
