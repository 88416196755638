import axios from "axios";

const api = axios.create();

// Request interceptor for API calls
api.interceptors.request.use(
  async (config) => {
    config.headers = {
      'Content-Type': 'application/json',
    };
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

export {api}