import { createColumnHelper } from '@tanstack/react-table';
import Table from '../conponents/table/Table';
import DateTime from '../conponents/excerpts/DateTime';
import { useDispatch, useSelector } from 'react-redux';
import { setEditMode, toggleDrawer } from '../features/actionSlice';
import { getOneUser, setUser } from '../features/userSlice';
import {  ButtonLight } from '../styles/DefaultStyles';
import { isEditable } from '@testing-library/user-event/dist/utils';

const Users = () => {
 
  const columnHelper = createColumnHelper();
  const columns = [
    columnHelper.accessor(`first_name`, {
      cell: (info) => info.getValue(),
      header: () => <span>Name</span>,
    }),
    columnHelper.accessor( 'last_name', {
      cell: (info) => info.getValue(),
      header: () => <span></span>,
    }),
    columnHelper.accessor("email", {
      cell: (info) => info.getValue(),
      header: () => <span>Email</span>,
    }),
    columnHelper.accessor("phone_number", {
      cell: (info) => info.getValue(),
      header: () => <span>Phone Number</span>,
    }),
    columnHelper.accessor("created_at", {
      cell: (info) =><DateTime val={info} date={info.getValue()} />,
      header: () => <span>Date</span>,
    }),
    columnHelper.accessor("action", {
      cell: (info) => (
        <ButtonLight small>
          <button
            onClick={(e) => {
              e.stopPropagation();
              onEnable(info.row.original.id);
            }}
          >
            Enable/Disable
          </button>
        </ButtonLight>
      ),
      header: () => <span>Action</span>,
    }),
  ];
  const {users} = useSelector((state)=> state.user)
  const dispatch = useDispatch()
  const onClick = (data) => {
    dispatch(getOneUser(data))
    dispatch(toggleDrawer('user_detail'))
  };
  const onEnable =(id)=>{
    dispatch(toggleDrawer('enable_disable_user'));
    dispatch(setEditMode("edit"))
    dispatch(setUser(id));
  }
  return (
    <div>
      <h1>All Users</h1>
     <div style={{ display: "flex", justifyContent: "flex-end" }}>
        
      </div>
      <Table
          data={users}
          columns={columns}
          click={onClick}
          filter={"true"}
          filterParams={true}
          name={'All Users'}
      />
    </div>
  )
}

export default Users
