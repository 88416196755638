import React from 'react';
import styled from 'styled-components';

const ErrorMsg = ({ msg }) => {
  return <ErrorWrap role="alert">{msg}</ErrorWrap>;
};

const ErrorWrap = styled.div`
  color: red;
  font-size: 14px;
  margin-top: 5px;
`;

export default ErrorMsg;
