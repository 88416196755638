import React from 'react';
import { Controller } from 'react-hook-form';
import Select from 'react-select';
import styled from 'styled-components';
import ErrorMsg from '../alert/ErrorMsg';

const CustomSelect = ({ name, method, options, label, error, ...rest }) => {
  const {
    control,
    formState: { errors },
  } = method;
  return (
    <Wrapper>
      <label htmlFor={name}>{label}</label>
      <Controller
        {...rest}
        control={control}
        render={({ field: { onChange, value, name, ref } }) => {
          const currentSelection = options.find((c) => c.value === value);
          const handleSelectChange = (selectedOption) => {
            onChange(selectedOption?.value);
          };
          return (
            <Select
              inputRef={ref}
              value={value ? currentSelection : []}
              name={name}
              options={options}
              onChange={handleSelectChange}
              {...rest}
            />
          );
        }}
        name={name}
        rules={{ required: true }}
      />

      {errors?.[name] && <ErrorMsg msg={errors[name]?.message} />}
      {error && <ErrorMsg msg={error.message.split('.')[1]} />}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  margin-bottom: 20px;

  input {
    height: 33px;
  }

  label {
    color: ${(props) => props.theme.color2};
    margin-bottom: 10px;
    display: block;
  }
`;

export default CustomSelect;
