import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { IoSearch, IoClose } from 'react-icons/io5';

const FIlterTable = ({
  value: initialValue,
  onChange,
  debounce = 500,
  ...props
}) => {
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value);
    }, debounce);

    return () => clearTimeout(timeout);
  }, [value]);

  return (
    <FIlterWrapper>
      <FilterInput>
        <span className="filter-icon">
          <IoSearch />
        </span>
        <input
          {...props}
          value={value}
          onChange={(e) => setValue(e.target.value)}
          placeholder="Search all columns"
        />
        <span className="filter-icon">
          {value && (
            <span className="filter-close" onClick={() => setValue('')}>
              <IoClose />
            </span>
          )}
        </span>
      </FilterInput>
    </FIlterWrapper>
  );
};

const FIlterWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const FilterInput = styled.div`
  position: relative;
  /* background-color: ${(props) => props.theme.card_bg}; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 5px;
  border: 1px solid #eee;

  input {
    border: none;
    outline: none;
    height: 45px;
    width: 200px;
    font-size: 16px;
    background: transparent;
    flex: 1;

    ::placeholder {
      color: ${(props) => props.theme.color2};
    }
  }

  span {
    color: #666;
  }

  .filter-icon {
    display: flex;
    height: 100%;
    width: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .filter-close {
    cursor: pointer;
    display: flex;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    padding: 5px;

    :hover {
      background-color: ${(props) => props.theme.accent_light};
    }
  }
`;

export default FIlterTable;
