import { configureStore } from "@reduxjs/toolkit";
import actionReducer from '../features/actionSlice';
import userReducer from '../features/userSlice';
import statReducer from '../features/statSlice';
import walletReducer from '../features/walletSlice';
import transactionReducer from '../features/transactionSlice';
import billReducer from '../features/billSlice';
import providerReducer from '../features/providerSlice';
import emailReducer from '../features/emailSlice';
import announcementReducer from '../features/announcementSlice';
import blogReducer from '../features/blogSlice';

export const store = configureStore({
    reducer:{
        action: actionReducer,
        user:userReducer,
        stat:statReducer,
        wallet:walletReducer,
        transaction:transactionReducer,
        bill:billReducer,
        provider:providerReducer,
        email:emailReducer,
        announcement:announcementReducer,
        blog:blogReducer,
    }
})