import React from "react";
import DrawerHeader from "../../drawer/DrawerHeader";
import { InnerWrapper, TrDetail, Wrapper } from "../form.styled";
import Amount from "../../excerpts/Amount";
import OnlyDate from "../../excerpts/OnlyDate";
import { useSelector } from "react-redux";


const BillCategoryDetails = ({ close, data }) => {
  const renderSource = (value) => {
    if (value === 1) return "Yes";
    else return "No";
  };
  const { details } = useSelector((state) => state.bill.billCategory);
  
  return (
    <Wrapper>
      <InnerWrapper>
        <DrawerHeader title="Bill Category Details" tag="view category item" close={close} />
        <div>
          <TrDetail>
            <span className="tr-title">Name</span>
            <span className="tr-data">{details.name}</span>
          </TrDetail>
          <TrDetail>
            <span className="tr-title">Service ID</span>
            <span className="tr-data">{details.serviceID}</span>
          </TrDetail>
          <TrDetail>
            <span className="tr-title">Minimum Amount</span>
            <span className="tr-data"><Amount amount={details.minimium_amount}/></span>
          </TrDetail>
          <TrDetail>
            <span className="tr-title">Maximum Amount</span>
            <span className="tr-data"><Amount amount={details.maximum_amount}/></span>
          </TrDetail>
          <TrDetail>
            <span className="tr-title">Convience Fee</span>
            <span className="tr-data"><Amount amount={details.convinience_fee}/></span>
          </TrDetail>
          <TrDetail>
            <span className="tr-title">Commissions</span>
            <span className="tr-data"><Amount amount={details.commissions}/></span>
          </TrDetail>
          <TrDetail>
            <span className="tr-title">Billers Service</span>
            <span className="tr-data">{details.billers_services}</span>
          </TrDetail>
          <TrDetail>
            <span className="tr-title">Date</span>
            <span className="tr-data">
              <OnlyDate date={details.created_at} />
            </span>
          </TrDetail>
          <TrDetail>
            <span className="tr-title">Fixed Price</span>
            <span className="tr-data">{details.product_type}</span>
          </TrDetail>
          <TrDetail>
            <span className="tr-title">Active ?</span>
            <span className="tr-data">{renderSource(details.enable)}</span>
          </TrDetail>
        </div>
      </InnerWrapper>
    </Wrapper>
  );
};

export default BillCategoryDetails;
