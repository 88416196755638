import React, { useState } from "react";
import { Button } from "../../styles/DefaultStyles";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch, useSelector } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import CustomInput from "../excerpts/Input";
import { notifyError, notifySuccess } from "../../utils/toast";
import {
  createBlogPosts,
  getBlogPosts,
} from "../../features/blogSlice";
import CustomSelect from "../excerpts/Select";
import { blogs } from "../../utils/mods";
import { EditorState, convertToRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import { Wrapper1 } from "../email/CreateEmail";
import { BtnWrapper, InnerWrapper } from "../forms/form.styled";

const schema = yup.object({
  title: yup.string().required(),
  summary: yup.string().required(),
  blogCategory: yup.string().required(),
  blogBy: yup.string().required(),
});
 
const CreateBlogPost = ({ close }) => {
  const { editMode } = useSelector((state) => state.action);
  const { blog, cats } = useSelector((state) => state.blog);

  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const isEdit = () => editMode === "edit";
  const method = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      title: isEdit() ? blog.title : "",
      summary: isEdit() ? blog.summary : "",
      body: isEdit() ? blog.body_html : "",
      blogCategory: isEdit() ? blog.blog_category_id : "",
      blogBy: isEdit() ? blog.blog_by : "",
    },
  });
  const { handleSubmit, reset } = method;

  const onSubmit = (data) => {
    setLoading(true);
    let payload = {
      title: data.title,
      summary: data.summary,
      body_html: draftToHtml(convertToRaw(editorState.getCurrentContent())),
      image_url:
        "https://sandbox.vtpass.com/resources/products/200X200/Jamb.jpg",
      blog_category_id: data.blogCategory,
      blog_by: data.blogBy,
      reading_time:"2 minutes read",
      status: true,
    };
    onCreateBlogPost(payload);
 
  };

 //text to html
 const [editorState, setEditorState] = useState(EditorState.createEmpty());
 const onEditorStateChange = (editorState) => {
   setEditorState(editorState);
 };
  
  
  const onCreateBlogPost = (data) => {
    setLoading(true);
    dispatch(createBlogPosts(data))
      .unwrap()
      .then(() => {
        setLoading(false);
        notifySuccess("Blog Post created successfully");
        dispatch(getBlogPosts());
        reset();
        close();
      })
      .catch((err) => {
        setLoading(false);
        notifyError(
          err?.data?.response_message ||
            err?.response_message ||
            "Failed to create Blog Post"
        );
      });
  };


  return (
    <Wrapper1>
      <InnerWrapper>
        <h2 style={{margin:"1rem 0"}}>Create Blog</h2>
        <CustomInput method={method} label="Title *" name="title" />
        <CustomInput method={method} label="Summary *" name="summary" />
        <div style={{border:"1px solid grey"}}>
        <Editor
          editorState={editorState}
          toolbarClassName="toolbarClassName"
          wrapperClassName="wrapperClassName"
          editorClassName="editorClassName"
          onEditorStateChange={onEditorStateChange}
        />
      </div>
        <CustomInput method={method} label="Blog by *" name="blogBy" />
        <CustomSelect
          method={method}
          options={blogs(cats)}
          label="Blog Category *"
          name="blogCategory"
        />
      </InnerWrapper>
      <BtnWrapper>
        <Button disabled={loading}>
          <button onClick={handleSubmit(onSubmit)}>
            {loading ? "Loading..." : "submit"}
          </button>
        </Button>
      </BtnWrapper>
    </Wrapper1>
  );
};

export default CreateBlogPost;
