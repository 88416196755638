import React, { useState } from "react";
import { Button } from "../../styles/DefaultStyles";
import DrawerHeader from "../drawer/DrawerHeader";
import { BtnWrapper, InnerWrapper, Wrapper } from "./form.styled";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch, useSelector } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import CustomInput from "../excerpts/Input";
import { notifyError, notifySuccess } from "../../utils/toast";
 
import {
  createBlogCategory,
  getBlogCategory,
  updateBlogCategory,
} from "../../features/blogSlice";

const schema = yup.object({
  name: yup.string().required(),
});

const titles = { add: "Add New Blog Category", edit: "Edit Blog Category" };
const btnTexts = { add: "Create Blog Category", edit: "Update Blog Category" };
const AddEditBlogCategory = ({ close }) => {
  const { editMode } = useSelector((state) => state.action);
  const { cat } = useSelector((state) => state.blog);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const isEdit = () => editMode === "edit";
  const method = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      name: isEdit() ? cat.name : "",
    },
  });
  const { handleSubmit, reset } = method;

  const onSubmit = (data) => {
    setLoading(true);
    let payload = {
      name: data.name,
      status: true,
    };

    if (editMode === "add") {
      onCreateBlogCategory(payload);
    } else {
      onUpdateBlogCategory(payload);
    }
  };

  const onCreateBlogCategory = (data) => {
    setLoading(true);
    dispatch(createBlogCategory(data))
      .unwrap()
      .then(() => {
        setLoading(false);
        notifySuccess("Blog Category created successfully");
        dispatch(getBlogCategory());
        reset();
        close();
      })
      .catch((err) => {
        setLoading(false);
        notifyError(
          err?.data?.response_message ||
            err?.response_message ||
            "Failed to create Blog Category"
        );
      });
  };

  const onUpdateBlogCategory = (data) => {
    const payload = {
      id: cat.id,
      data,
    };
    dispatch(updateBlogCategory(payload))
      .unwrap()
      .then(() => {
        setLoading(false);
        notifySuccess("Blog Category updated successfully");
        dispatch(getBlogCategory());
        reset();
        close();
      })
      .catch((err) => {
        setLoading(false);
        notifyError(
          err?.data?.response_message ||
            err?.response_message ||
            "Failed to update Blog Category"
        );
      });
  };

  return (
    <Wrapper>
      <InnerWrapper>
        <DrawerHeader title={titles[editMode]} close={close} />
        <CustomInput method={method} label="Name *" name="name" />
      </InnerWrapper>
      <BtnWrapper>
        <Button disabled={loading}>
          <button onClick={handleSubmit(onSubmit)}>
            {loading ? "Loading..." : btnTexts[editMode]}
          </button>
        </Button>
      </BtnWrapper>
    </Wrapper>
  );
};

export default AddEditBlogCategory;
