import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api } from "../service/axiosinstance";
import { BASE_URL } from "../utils/constants";

const initialState = {
  status: "loading",
  users: [],
  user: null,
};

export const getAllUsers = createAsyncThunk(
  "user/getAllUsers",
  async (_, thunkApi) => {
    try {
      const { data } = await api.get(`${BASE_URL}/admin/users`);
      return data;
    } catch (err) {
      return thunkApi.rejectWithValue(err.response);
    }
  }
);

export const enableDisableUser = createAsyncThunk(
  "user/EnableDisableUser",
  async (payload, thunkAPI) => {
    try {
      const { data } = await api.post(
        `${BASE_URL}/admin/disable_or_enable_users`,
        payload
      );

      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response);
    }
  }
);

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    getOneUser(state, action) {
      state.user = action.payload;
    },
    setUser(state, action) {
      const id = action.payload;
      state.user = state.users.find((user) => user.id === id);
    },
  },
  extraReducers: {
    // Get all Transactions
    [getAllUsers.pending]: (state) => {
      state.status = "loading";
    },
    [getAllUsers.fulfilled]: (state, action) => {
      state.status = "idle";
      state.users = action.payload.data;
    },
    [getAllUsers.rejected]: (state) => {
      state.status = "idle";
    },
  },
});
export const { getOneUser,setUser } = userSlice.actions;
export default userSlice.reducer;
