import styled from 'styled-components';

export const TableWrapper = styled.div`
  width: 100%;
  overflow-x: auto;

  table {
    width: 100%;
    min-width: 1000px;
    border-spacing: 0;
    border-collapse: collapse;
    background-color: ${(props) => props.theme.secondary};
    tr {
      td,
      th {
        padding: 20px 10px;
      }
      :last-child {
        td {
          border-bottom: 0;
        }
      }
      :nth-child(even) {
        background-color: ${(props) => props.theme.primary};
      }
    }

    .table-click {
      cursor: pointer;
    }

    thead > tr {
      border-top: 0;
    }

    tbody {
      overflow: scroll;
    }

    tbody > tr {
      border-top: 2px solid ${(props) => props.theme.card_bg};
      cursor: default;
      :hover {
        background-color: ${(props) => props.theme.card_bg};
      }
      td {
        max-width: 200px;
        white-space: pre-wrap;
      }
    }

    th,
    td {
      margin: 0;
      padding: 0.5rem;
      text-align: left;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;

      :last-child {
        border-right: 0;
      }
    }
  }
`;

export const TableHead = styled.div`
  display: flex;
  justify-content: ${(props) =>
    props.filterParams ? 'space-between' : 'flex-end'};
  align-items: center;
  margin: 20px 0;
  flex-wrap: wrap;
  gap: 20px;

  .search-filter {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 20px;
    flex: ${(props) => (props.filterParams ? '1' : '')};
  }

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-end;

    .children {
      order: 1;
    }

    .search-filter {
      order: 2;
      width: 100%;
      justify-content: flex-end;

      .table-search {
        order: 1;
      }
      .table-filter {
        order: 2;
        align-items: flex-start;
      }
    }

    div:last-child {
      margin-left: 0;
    }
  }
`;

export const HeadChildren = styled.div`
  display: flex;
  @media (max-width: 768px) {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    order: 1;
  }
`;

export const FilterParams = styled.div`
  display: flex;
  font-size: 16px;
  font-weight: 400;
  flex: 1;

  .pl {
    margin-left: 20px;
  }

  .colored {
    color: ${(props) => props.theme.accent};
  }

  .flex {
    display: flex;
  }
`;

export const Status = styled.div`
  width: 100px;
  text-align: center;
  padding: 6px 10px;
  border-radius: 15px;
  font-size: 14px;
  background-color: ${(props) => props.theme[props.status]};
  color: ${(props) => props.theme[props.status + '_dark']};
`;

export const PaginationWrapper = styled.div`
  padding: 30px 0;
  display: flex;
  justify-content: flex-end;

  .main-pagination {
    border: 1px solid #e5e5e5;
    border-radius: 6px;
    display: flex;
    overflow: hidden;
  }

  button {
    padding: 5px;
    font-size: 25px;
    width: 50px;
    height: 40px;
    border: none;
    outline: none;
    border-right: 1px solid #e5e5e5;
    color: ${(props) => props.theme.color1};
    cursor: pointer;

    :disabled {
      color: ${(props) => props.theme.color2};
    }
  }

  select {
    height: 100%;
    border: none;
    outline: none;
    background-color: #fff;
    appearance: none;
    width: 100px;
    padding-left: 20px;
    font-size: 14px;
  }
`;
