import React from "react";
import DrawerHeader from "../../drawer/DrawerHeader";
import { InnerWrapper, TrDetail, Wrapper } from "../form.styled";
import Amount from "../../excerpts/Amount";
import OnlyDate from "../../excerpts/OnlyDate";
import { useSelector } from "react-redux";
import Switch from "../../excerpts/Switch";
import { useState } from "react";

const WalletDetails = ({ close, data }) => {
  const { wallet } = useSelector((state) => state.wallet);
  const [activate, setActivate] = useState(wallet?.active);
  return (
    <Wrapper>
      <InnerWrapper>
        <DrawerHeader title="Wallet Details" tag="view Wallet" close={close} />
        <div>
          <TrDetail>
            <span className="tr-title">Name</span>
            <span className="tr-data">{wallet.name}</span>
          </TrDetail>
          <TrDetail>
            <span className="tr-title">Currency</span>
            <span className="tr-data">{wallet.currency}</span>
          </TrDetail>
          <TrDetail>
            <span className="tr-title">Ledger Balance</span>
            <span className="tr-data"><Amount amount={wallet.ledger_balances}/></span>
          </TrDetail>
          <TrDetail>
            <span className="tr-title">Avaliable Balance</span>
            <span className="tr-data"><Amount amount={wallet.available_balances}/></span>
          </TrDetail>
          <TrDetail>
            <span className="tr-title">Date</span>
            <span className="tr-data">
              <OnlyDate date={wallet.created_at} />
            </span>
          </TrDetail>
          <TrDetail>
            <span className="tr-title">Active</span>
            <span className="tr-data"><Switch   checked={activate}
      onChecked={() => setActivate(!activate)}/></span>
          </TrDetail>

        </div>
      </InnerWrapper>
    </Wrapper>
  );
};

export default WalletDetails;
