import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api } from "../service/axiosinstance";
import { BASE_URL } from "../utils/constants";

const initialState = {
  status: "loading",
  email: {},
  emails: [],
  category:[]
}
export const createEmail = createAsyncThunk(
  "createEmail/email",
  async (payload, thunkAPI) => {
    try {
      const { data } = await api.post(
        `${BASE_URL}/admin/send_mail_to_user`,
        payload
      );
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response);
    }
  }
);

export const getEmails = createAsyncThunk(
  "getEmails/email",
  async (_, thunkAPI) => {
    try {
      const { data } = await api.get(`${BASE_URL}/admin/get_mail_history`);
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response);
    }
  }
);

export const getEmailUsers = createAsyncThunk(
  "getEmailUsers/email",
  async (_, thunkAPI) => {
    try {
      const { data } = await api.get(`${BASE_URL}/admin/get_user_category`);
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response);
    }
  }
);
const emailSlice = createSlice({
  name: "email",
  initialState,
  reducer: {
    setOneEmail(state,action){
        state.email = action.payload
    }
  },
  extraReducers: {
    [getEmails.pending]: (state) => {
      state.status = "loading";
    },
    [getEmails.fulfilled]: (state, action) => {
      state.status = "idle";
      state.emails = action.payload.data;
    },
    [getEmails.rejected]: (state) => {
      state.status = "idle";
    },
    [getEmailUsers.pending]:(state)=>{
      state.status ='loading'
    },
    [getEmailUsers.fulfilled]:(state,action)=>{
      state.status ='idle';
      state.category = action.payload.data
    },
    [getEmailUsers.pending]:(state)=>{
      state.status ='idle'
    },
  },
});
export const {setOneEmail}= emailSlice.actions
export default emailSlice.reducer;
