import React from "react";
import DateTime from "../excerpts/DateTime";
import Status from "../excerpts/Status";
import Amount from "../excerpts/Amount";
import { createColumnHelper } from "@tanstack/react-table";
import Table from "../table/Table";
import { useDispatch, useSelector } from "react-redux";
import { setBillSubTransactionDetails } from "../../features/transactionSlice";
import { toggleDrawer } from "../../features/actionSlice";
const SubTransactions = () => {
  const columnHelper = createColumnHelper();
  const columns = [
    columnHelper.accessor("product_name", {
      cell: (info) => info.getValue(),
      header: () => <span>Product</span>,
    }),
    columnHelper.accessor("amount", {
      cell: (info) => <Amount amount={info.getValue()} />,
      header: () => <span>Amount</span>,
    }),
    columnHelper.accessor("email", {
      cell: (info) => info.getValue(),
      header: () => <span>Email</span>,
    }),
    columnHelper.accessor("phone", {
      cell: (info) => info.getValue(),
      header: () => <span>Phone</span>,
    }),
    columnHelper.accessor("status", {
      cell: (info) => <Status status={info.getValue()} />,
      header: () => <span>Status</span>,
    }),
    columnHelper.accessor("date", {
      cell: (info) => <DateTime val={info} date={info.getValue()} />,
      header: () => <span>Date</span>,
    }),
  ];
  const { data } = useSelector((state) => state.transaction.billSubscriptions);
  const dispatch = useDispatch();
  const onClick = (data) => {
    dispatch(setBillSubTransactionDetails(data));
    dispatch(toggleDrawer("bill_sub_transactions"));
  };
  return (
    <div>
      <Table
        data={data}
        columns={columns}
        click={onClick}
        filter={"true"}
        filterParams={true}
      />
    </div>
  );
};

export default SubTransactions;
