import React, { useEffect, useState } from 'react'
import AllWallets from '../conponents/wallet/AllWallets';
import WalletTransactions from '../conponents/wallet/WalletTransactions';
import Tab from '../conponents/tabs/Tab';
import WalletSettlement from '../conponents/wallet/WalletSettlement';
import { useDispatch } from 'react-redux';
import { getAllWallets, getWalletSettlement, getWalletTransactions } from '../features/walletSlice';

const Wallet = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAllWallets());
    dispatch(getWalletTransactions());
    dispatch(getWalletSettlement());
 
  }, [dispatch]);
  const tabList = [
    { name: 'All Wallets', alias: 'all-wallets'},
    { name: 'Wallet Transactions', alias: 'wallet-transactions'},
    {name:'Wallet Settlements', alias:'wallet-settlements'}
    
  ];
  const [activeTab, setActiveTab]=useState('all-wallet')
  return (
    <div>
       <div style={{margin:"2rem 0"}}>
            <h1>
                Wallet
            </h1>
        </div>
      <Tab tabs={tabList} activeTab={activeTab} setActiveTab={setActiveTab} />
      <div>
        { activeTab === tabList[0].alias && <AllWallets /> }
        { activeTab === tabList[1].alias && <WalletTransactions /> }
        { activeTab === tabList[2].alias && <WalletSettlement /> }
        
        </div>
    </div>
  )
}

export default Wallet
