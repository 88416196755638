import React, { useEffect, useState } from "react";
import Tab from "../conponents/tabs/Tab";
import BlogPost from "../conponents/blogs/BlogPost";
import BlogCategory from "../conponents/blogs/BlogCategory";
import CreateBlogPost from "../conponents/blogs/CreateBlogPost";
import { useDispatch } from "react-redux";
import { getBlogCategory, getBlogPosts } from "../features/blogSlice";

const Blogs = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getBlogPosts());
    dispatch(getBlogCategory());
  }, [dispatch]);

  const tabList = [
    { name: "Create Blog", alias: "create-blog" },
    { name: "Blog Post", alias: "blog-post" },
    { name: "Blog Category", alias: "blog-category" },
  ];
  const [activeTab, setActiveTab] = useState("create-blog");
  return (
    <div>
      <div style={{ margin: "2rem 0" }}>
        <h1>Blogs</h1>
      </div>
      <Tab tabs={tabList} activeTab={activeTab} setActiveTab={setActiveTab} />
      <div>
        {activeTab === tabList[0].alias && <CreateBlogPost />}
        {activeTab === tabList[1].alias && <BlogPost />}
        {activeTab === tabList[2].alias && <BlogCategory />}
      </div>
    </div>
  );
};

export default Blogs;
