import React, { useState } from "react";
import { Button } from "../../styles/DefaultStyles";
import DrawerHeader from "../drawer/DrawerHeader";
import { BtnWrapper, InnerWrapper, Wrapper } from "./form.styled";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch, useSelector } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import CustomInput from "../excerpts/Input";
import { notifyError, notifySuccess } from "../../utils/toast";
import {
  createBlogPosts,
  getBlogPosts,
  updateBlogPosts,
} from "../../features/blogSlice";
import CustomSelect from "../excerpts/Select";
import { blogs } from "../../utils/mods";

const schema = yup.object({
  title: yup.string().required(),
  summary: yup.string().required(),
  body: yup.string().required(),
  blogCategory: yup.string().required(),
  blogBy: yup.string().required(),
});

// const time = [
//   { label: "2 Minutes Read", value: "2 " },
//   { label: "4 Minutes Read", value: "4 " },
//   { label: "5 Minutes Read", value: "5 " },
// ];
const titles = { add: "Add New Blog Post", edit: "Edit Blog Post" };
const btnTexts = { add: "Create Blog Post", edit: "Update Blog Post" };
const AddEditBlogPost = ({ close }) => {
  const { editMode } = useSelector((state) => state.action);
  const { blog, cats } = useSelector((state) => state.blog);

  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const isEdit = () => editMode === "edit";
  const method = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      title: isEdit() ? blog.title : "",
      summary: isEdit() ? blog.summary : "",
      body: isEdit() ? blog.body_html : "",
      blogCategory: isEdit() ? blog.blog_category_id : "",
      blogBy: isEdit() ? blog.blog_by : "",

    },
  });
  const { handleSubmit, reset } = method;

  const onSubmit = (data) => {
    setLoading(true);
    let payload = {
      title: data.title,
      summary: data.summary,
      body_html: data.body,
      image_url:
        "https://sandbox.vtpass.com/resources/products/200X200/Jamb.jpg",
      blog_category_id: data.blogCategory,
      blog_by: data.blogBy,
      reading_time:"2 minutes read",
      status: true,
    };

    if (editMode === "add") {
      onCreateBlogPost(payload);
    } else {
      onUpdateBlogPost(payload);
    }
  };

  const onCreateBlogPost = (data) => {
    setLoading(true);
    dispatch(createBlogPosts(data))
      .unwrap()
      .then(() => {
        setLoading(false);
        notifySuccess("Blog Post created successfully");
        dispatch(getBlogPosts());
        reset();
        close();
      })
      .catch((err) => {
        setLoading(false);
        notifyError(
          err?.data?.response_message ||
            err?.response_message ||
            "Failed to create Blog Post"
        );
      });
  };

  const onUpdateBlogPost = (data) => {
    const payload = {
      id: blog.id,
      data,
    };
    dispatch(updateBlogPosts(payload))
      .unwrap()
      .then(() => {
        setLoading(false);
        notifySuccess("Blog Post updated successfully");
        dispatch(getBlogPosts());
        reset();
        close();
      })
      .catch((err) => {
        setLoading(false);
        notifyError(
          err?.data?.response_message ||
            err?.response_message ||
            "Failed to update Blog Post"
        );
      });
  };

  return (
    <Wrapper>
      <InnerWrapper>
        <DrawerHeader title={titles[editMode]} close={close} />
        <CustomInput method={method} label="Title *" name="title" />
        <CustomInput method={method} label="Summary *" name="summary" />
        <CustomInput method={method} label="Body *" name="body" />
        <CustomInput method={method} label="Blog by *" name="blogBy" />
        <CustomSelect
          method={method}
          options={blogs(cats)}
          label="Blog Category *"
          name="blogCategory"
        />
      </InnerWrapper>
      <BtnWrapper>
        <Button disabled={loading}>
          <button onClick={handleSubmit(onSubmit)}>
            {loading ? "Loading..." : btnTexts[editMode]}
          </button>
        </Button>
      </BtnWrapper>
    </Wrapper>
  );
};

export default AddEditBlogPost;
