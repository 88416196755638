import React, { useRef } from "react";
import SideNav from "./SideNav";
import { Outlet } from "react-router-dom";
import styled from "styled-components";
import DrawerMain from "../../conponents/drawer/DrawerMain";
import { ToastContainer } from "react-toastify";

export const Wrapper = styled.div`
  display: flex;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
`;
export const Main = styled.div`
  flex: 1;
  overflow-y: auto;
  padding: 50px 0;

  @media (max-width: 700px) {
    padding: 30px 0;
  }
`;

export const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
`;

const Layout = () => {
  const mainRef = useRef();

  return (
    <Wrapper>
        <ToastContainer />
      <SideNav />
      <Main ref={mainRef}>
        <Container>
          <Outlet />
        </Container>
      </Main>
      <DrawerMain />
    </Wrapper>
  );
};

export default Layout;
