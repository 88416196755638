import { useEffect } from "react";


export const test_api = "https://sandbox.paymoni.app/api"//"https://paymoni-api-staging-weq5f.ondigitalocean.app/api";
export const live_api = "https://production-api.paymoni.app/api"//"https://paymoni-live-api-e9438.ondigitalocean.app/api";

const getbaseUrl = () => {
  if (typeof window !== "undefined") {
    console.log(  window.location.href.includes("localhost"))
    return window.location.href.includes("test") ||
      window.location.href.includes("localhost")
      ? test_api
      : live_api;
  }
};

export const BASE_URL = getbaseUrl();
