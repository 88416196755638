import React from 'react';
import DrawerHeader from '../drawer/DrawerHeader';
 

const Pay = ({ close }) => {
  return (
    <>
     <DrawerHeader
          title="Transaction Details"
          tag="View transaction record"
          close={close}
        />
     this is me testing
    </>
  );
};

export default Pay;
