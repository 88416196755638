import React from 'react';
import styled from 'styled-components';

const Switch = ({ checked, onChecked, name, size }) => {
  return (
    <SwitchWrap htmlFor={name} size={size} >
      <input
        className="switch-input"
        type="checkbox"
        id={name}
        name={name}
        checked={checked}
        onChange={onChecked}
      />
      <div className="toggler"></div>
    </SwitchWrap>
  );
};

const SwitchWrap = styled.label`
  position: relative;

  .toggler {
    width: ${(props) => (props.size === 'lg' ? '50px' : '30px')};
    height: ${(props) => (props.size === 'lg' ? '25px' : '15px')};
    background-color: #ccc;
    border-radius: ${(props) => (props.size === 'lg' ? '25px' : '10px')};
    cursor: pointer;
    position: relative;
    transition: all 300ms;

    ::before {
      content: '';
      width: ${(props) => (props.size === 'lg' ? '25px' : '15px')};
      height: 100%;
      border-radius: 50%;
      background-color: #555;
      position: absolute;
      border: 2px solid #ccc;
      transition: all 300ms;
    }
  }

  .switch-input:checked + .toggler:before {
    background-color: ${(props) => props.theme.accent};
    transform: ${(props) =>
      props.size === 'lg' ? 'translateX(25px)' : 'translateX(15px)'};
  }

  .switch-input {
    opacity: 0;
    visibility: hidden;
    width: 0;
    height: 0;
    position: absolute;
  }
`;

export default Switch;
