import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
*, 
*::after,
*::before {
  padding: 0;
  box-sizing: border-box;
  margin: 0;
}

.Toastify__toast-container {
  z-index: 999999999
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'GT Welsheim Pro';
}

button, input, option, select, textarea {
  font-family: 'GT Welsheim Pro';

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.sp-icon {
  fill: ${(props) => props.theme.color3};
}

.sp-icon path {
  fill: ${(props) => props.theme.color3};
}

.disabled {
  pointer-events: none;
  opacity: .6;
}
`;
