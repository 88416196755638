import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  animation: fadeIn 300ms;

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

export const InnerWrapper = styled.div`
  flex: 1;
  overflow: auto;
  padding: 40px 20px;
  width: 100%;
`;
export const BtnWrapper = styled.div`
  bottom: 0;
  gap: 20px;
  padding: 20px;

  div,
  a {
    text-decoration: none;
    width: 100%;
    button {
      padding: 15px;
      width: 100%;
    }
  }
`;
export const Heading = styled.h3`
  font-family: 'Recoleta';
  font-size: 18px;
  margin-bottom: 15px;
`;

export const TrDetail = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 25px 0;
  border-bottom: 2px solid grey;

  :last-child {
    border-bottom: none;
  }

  .tr-title {
    color: black;
  }

  .tr-data {
    width: 65%;
    color: black;
    text-align: left;

  }
`;

export const CardBoxWrap = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 50px;
`;

export const CardBox = styled.div`
  background-color: whitesmoke;
  margin-bottom: 20px;
  padding: 20px;
  border-radius: 20px;
  display: flex;
  cursor: pointer;
  transition: ${(props) => props.theme.transition1};
  position: relative;

  :hover {
    background-color: ${(props) => props.theme.accent_light};
  }

  .card_box-text {
    h2 {
      font-weight: 400;
      font-size: 20px;
      margin-bottom: 10px;
      color: ${(props) => props.theme.accent};
    }

    p {
      color: ${(props) => props.theme.color2};
    }
  }

  .card_box-icon {
    margin-right: 20px;
    color: ${(props) => props.theme.accent};
  }

  .card_offline {
    position: absolute;
    right: 10px;
    top: 10px;
    font-size: 12px;
    color: ${(props) => props.theme.color2};
    font-weight: 600;
    background-color: ${(props) => props.theme.accent_light};
    padding: 3px 5px;
    border-radius: 3px;
  }
`;

export const PaymentCard = styled.div`
  margin-top: 60px;
  background-color: ${(props) => props.theme.card_bg};
  padding: 50px 30px;
  border-radius: 6px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  justify-content: space-between;
  margin-bottom: 40px;

  p {
    color: ${(props) => props.theme.color2};
    margin-bottom: 10px;
    font-size: 15px;
  }

  .amount {
    display: flex;
    flex-direction: column;

    .amount-discount {
      display: flex;
      font-size: 12px;
      text-decoration: line-through;
      color: ${(props) => props.theme.danger};
    }
  }

  h3 {
    font-family: 'Recoleta';
    font-size: 16px;
    color: ${(props) => props.theme.color5};
  }
`;

export const WalletInput = styled.div`
  display: flex;
  flex-direction: column;
  p {
    margin-bottom: 10px;
    color: ${(props) => props.theme.color5};
  }

  .code-input-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const AccountList = styled.div`
  .acct-label {
    color: ${(props) => props.theme.color2};
    font-size: 18px;
    margin-bottom: 10px;
  }

  .acct-card {
    background-color: ${(props) => props.theme.card_bg};
    padding: 20px;
    border-radius: 5px;
    margin-bottom: 30px;
    cursor: pointer;

    :hover {
      background-color: ${(props) => props.theme.accent_light};
    }
    p {
      color: ${(props) => props.theme.clor2};
      margin-bottom: 10px;
      color: ${(props) => props.theme.color2};
    }

    h3 {
      color: ${(props) => props.theme.color5};
      font-size: 18px;
    }
  }
`;

export const AcctName = styled.div`
  background-color: ${(props) => props.theme.card_bg};
  padding: 15px;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  svg {
    height: 20px;
    width: 20px;
  }
`;

export const StaffInfo = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
  margin-bottom: 30px;

  img {
    width: 60px;
    height: 60px;
    border-radius: 5px;
  }

  .staff-details {
    h3 {
      margin-bottom: 10px;
      font-family: 'Recoleta';
    }

    p {
      color: ${(props) => props.theme.color2};
      span {
        margin: 0 10px;
      }
    }
  }
`;

export const InputCard = styled.div`
  background-color: ${(props) => props.theme.card_bg};
  padding: 15px;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const GenerateLinkWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
  p {
    color: ${(props) => props.theme.accent};
    font-weight: 600;
  }
`;

export const DirectorList = styled.div``;
export const DirectorWrap = styled.div`
  margin-bottom: 30px;
  .trash-wrap {
    display: flex;
    justify-content: flex-end;
    font-size: 20px;
    color: ${(props) => props.theme.danger};

    span {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      :hover {
        background-color: ${(props) => props.theme.accent_light};
      }
    }
  }
`;
export const AddDirectorField = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  p {
    font-weight: 600;
    color: ${(props) => props.theme.accent_dark};
    padding: 5px;
    border-radius: 10px;
    cursor: pointer;
    :hover {
      text-decoration: underline;
    }
  }
`;
